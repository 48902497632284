.AccountOverview__Card__Container {

    width: 100%;

    border: 1px solid #D71513;
        background-color: #ffffff;
       
        border-radius: 5px;
        padding: 2rem;

    .AccountOverview__Available__Card {
        
        display: flex;
        align-items: start;
        gap: 2rem;
        margin-bottom: 1.5rem;

        .AccountOverview__Available__Icon {
            width: 38px;
        }

        .AccountOverview__Available {
            color: #D71513;
            font-family: "Poppins";
            margin-bottom: 0.1rem;
        }

        .AccountOverview__Available__Text {
            color: gray;
            font-family: "Poppins";
            margin: 0;
        }
    }

}
.AccountOverview__Bottom__Container{
    padding-top: 1rem;
    border-top: 1px solid #EBEBEB;
    .AccountOverview__Bottom__Inner__Container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "Poppins";
        .Bottom__Inner__Right__Container{
            display: flex;
            align-items: start;
        }
        .Bottom__Inner__Right__Container__Text{
        }
       
    }
       
    
    //Progess Bar scss
    .Bottom__Main__Container{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .Bottom__Container__Progress__Bar{
            display: flex;
            align-items: center;
            margin-top: 1rem;
            width: 100%;
            .Progess__Bar__Color__Filled__Circle{
                border-radius: 50%;
                border:4px solid #FDD81F;
                padding: 3px 6px;
            }
            .Progess__Bar__Line{
                background-color: #EAEAEA;
                    width: 100%;
                    height: 4px;
                    opacity: 1;
                    color: transparent;
            }
            .ProgessBar__Line__Colored{
                background-color: #FDD81F;
                    width: 100%;
                    height: 4px;
                    opacity: 1;
                    color: transparent;
            }
            img{
                width: 21px;
                margin-bottom: 2px;
            }
            .Not__show{
                display: none;
            }
            .Progess__Bar__Not__Filled__Circle{
                border-radius: 50%;
                background-color: #EAEAEA;
                    padding: 20px;
            }
        }
    }
}
@media screen and (max-width:400px){
    .Progess__Bar__Color__Filled__Circle {
            border-radius: 50%;
            border: 2px solid #FDD81F !important;
                padding: 0px 5px !important;
            img{
        
                width: 15px !important;
            }
        }
                .Progess__Bar__Not__Filled__Circle {
                    border-radius: 50%;
                    background-color: #EAEAEA;
                    padding: 15px !important;
                }
}
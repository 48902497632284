.product {
  &__block {
    width: 100%;
    height:100%;
    overflow: hidden;
    border-radius: 5px;
    // padding: 0.5rem;
    box-shadow: 0px 1px 3px 3px rgba(16, 24, 40, 0.05);

    .product__action__block {
      display: none;
      transition: top ease 0.5s;
    }

    &:hover {
      .product__action__block {
        opacity: 1;
        position: absolute;
        display: flex;
        bottom:10px
      }
    }
  }
  &__thumb__block {
    width: 220px;
    height: 220px;
    align-self: stretch;
    margin: 0 auto;
    // padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;  // Ensure the image covers the full width
      height: 100%;  // Ensure the image covers the full height
      // object-fit: cover;  // Maintain aspect ratio and cover the container
    }
  }
  &__content__block {
    display: flex;
    padding: 10px 3px 15px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    a {
      text-decoration: none;
    }
  }
  &__content__details__block {
    position: relative;
    width: 100%;
  }
  &__title {
    color: var(--base-black, #1c1c1c);
    align-self: stretch;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    // width: 220px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: wrap;
  }
  &__price__block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    /* 12px/Regular */
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__price--original {
    color: var(--Grey---767676, #888686);
  }
  &__price--after--discount {
    color: var(--base-black, #1c1c1c);
  }
  &__price--discount-percantage {
    color: #eb5806;
  }
  &__coin__block {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  &__coin--title {
    color: var(--Grey---767676, #888686);
    /* 12px/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__coin {
    color: var(--base-black, #1c1c1c);
    /* 12px/Medium */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
  &__action__block {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
    // position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease-in;
  }
  &__cart__btn {
    display: flex;
    height: 32px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 5px;
    border: 1px solid var(--Primary---D71513, #d71513);
    background: var(--Primary---D71513, #d71513);
    color: var(--base-white, #fff);
    /* 14px/Medium */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__cart__btn__disable {
    cursor: not-allowed !important;
  }
  &__wishlist__btn {
    width: 45px;
    height: 30px;
    flex-shrink: 0;
    color: #1c1c1c;
    display: flex;
    svg {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 512px) {
  .product__thumb__block {
    width: 100%;
    height: auto;
  }
  .product__coin__block {
    margin-top: 0.2rem !important;
  }
  .product__coin--text {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 450px) and (min-width: 400px) {
  .product__thumb__block {
    width: 100% !important;
  }
}
@media screen and (max-width: 500px) {
  .product__thumb__block {
    width: 100% !important;
    height: 175px;

    padding:0px
    img{
      height: 100%;
    }
  }
}

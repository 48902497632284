.home-02-icon2CatalogueRoutesNaming {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .breadcrumb-button-base4CatalogueRoutesNaming {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .text61CatalogueRoutesNaming {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .breadcrumb-button-base7CatalogueRoutesNaming {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--primary-d71513);
  }
  .breadcrumbsCatalogueRoutesNaming {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .breadcrumbs-wrapperCatalogueRoutesNaming {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xl) var(--padding-61xl);
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-sm-medium-size);
    color: var(--grey-600);
    font-family: var(--text-md-semibold);
  }
  
.divider {
    position: relative;
    background-color: var(--grey-200);
    width: 100vw;
    height: 1px;
  }
  .header-navigationAccount {
    align-self: stretch;
    background-color: var(--base-white);
    border-bottom: 1px solid var(--grey-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  // .dropdown-groupEditProfile{
  //   position:relative
  // }
  .accountAccount {
    position: relative;
    font-size: 23px;
    font-weight: 500;
    font-family: "Poppins";
  }
  .e-invoice__block {
    width: 100%;
    overflow: hidden;
    .react-tabs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .react-tabs__tab-panel {
      display: none;
      width: 100%;
      overflow: hidden;
    }
    .react-tabs__tab-panel--selected {
      display: block;
      width: 100%;
      overflow: hidden;
    }
    .react-tabs__tab-list {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #888686;
    }
    @media screen and (max-width: 970px) {
      .react-tabs__tab-list {
        flex-direction: row !important;
        gap: 0rem !important;
      }
    }
    .react-tabs__tab {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      background: transparent;
      color: var(--Grey---767676, #888686);
      /* 24px/Semibold */
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      position: initial;
    }
    .react-tabs__tab--selected {
      border: none;
      color: var(--Primary---D71513, #d71513);
      border-bottom: 1px solid #d71513;
    }
  }
  .backArrow{
    color: "#D71513" !important;
    width: "5%";
    height: "5%";
  }
  .steven-robertAccount {
    position: relative;
    font-size: var(--text-md-semibold-size);
    font-weight: 500;
    color: var(--primary-d71513);
    text-transform: capitalize;
  }
  .account-parentAccount {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-childAccount {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--color-gainsboro);
    box-sizing: border-box;
    height: 1px;
  }
  .frame-groupAccount {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0px var(--padding-13xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18px;
  }
  .dropdown-iconAccount {
    position: relative;
    background-color: var(--grey-200);
    border-radius: 50%;
    width: 90px;
    height: 90px;
  }
  .full-nameAccount {
    display: flex;
      align-self: flex-start;
    position: relative;
    text-transform: capitalize;
  }
  @media screen and (max-width: 576px) {
      .full-nameAccount {
        word-break: break-word;
      }
    }
    .full-nameAccountEmail {
      display: flex;
      align-self: flex-start;
      position: relative;
    }
    @media screen and (max-width: 576px) {
      .full-nameAccountEmail {
        word-break: break-all;
      }
    }
  .full-name-parentAccount {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
  }
  .frame-divAccount {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  .label-text1Account {
    position: relative;
    font-weight: 600;
    color: var(--base-white);
  }
  .state-layer1Account {
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) 7px;
    align-items: center;
    justify-content: center;
  }
  .buttonAccount {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--m3-label-large-size);
    color: var(--base-white);
    outline: none;
    border: none;
    font-family: 'Poppins', sans-serif;
  }

  .buttonAccount:hover {
    box-shadow: 4px 4px 10px rgba(13, 14, 15, 0.277);

  }

  .dropdown-parentAccount {
    overflow: hidden;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.03);
    width: auto;
    display: flex;
    flex-direction: column;
    padding: var(--padding-13xl) var(--padding-45xl);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    font-size: var(--text-md-semibold-size);
    color: var(--base-black);
    font-family: var(--text-md-semibold);
    align-self: stretch;

    &-e-invoice {
      overflow: hidden;
      border-radius: var(--br-5xs);
      background-color: var(--base-white);
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.03);
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1px;
      font-size: var(--text-md-semibold-size);
      color: var(--base-black);
      font-family: var(--text-md-semibold);
      align-self: stretch;
    }
  }
  .profile-details-parentAccount {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 22px;
  }
  .frame-containerAccount {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xl) var(--padding-13xl);
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gap-13xl);
  }
  .frame-parentAccount {
    align-self: stretch;
    background-color: var(--color-ghostwhite);
    display: flex;
    flex-direction: column;
    padding: var(--padding-13xl) 80px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--color-darkslategray-100);
    font-family: var(--font-work-sans);
  }
  .footerAccount {
    align-self: stretch;
    background-color: var(--grey-800);
    display: flex;
    flex-direction: column;
    padding: var(--padding-45xl) 0px var(--padding-29xl);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }
  .profileAccount {
    position: relative;
    background-color: var(--base-white);
    width: 100vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // align-items: flex-start;
    // justify-content: flex-start;
  }


  // for account links tab mobile screens

  .mobAccountTabMain {
    display: flex;
    gap: 15px;
  }

.mobAccountTabIcon {
  width: 30px;
  height: auto;
}


.account-parentAccount {
  display: flex;
  flex-direction: column;
}


.accountTabMenuIcon {
  width: 30px;
  height: auto;
}


.frame-parent1Tab {
  position: absolute;
  background: white;
  z-index: 10;
  width: 100vw;
  align-self: stretch;
  border-right: 1px solid var(--color-gainsboro);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-dimgray-100);
  font-family: var(--font-work-sans);
  padding: 16px;
}


.orders-wrapperTab {
  cursor: pointer;
  border: none;
  padding: var(--padding-3xs) 0px var(--padding-9xl);
  background-color: transparent;
  align-self: stretch;
  border-bottom: 1px solid var(--color-gainsboro);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


.ordersTab {
  position: relative;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  color: var(--color-dimgray-100);
  text-align: left;
}


.log-out-03-parentTab {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) 0px var(--padding-mini);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  color: var(--color-red-100);
  background: transparent;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.overviewTab {
  position: relative;
}
  
  @media screen and (max-width: 960px) {
    .profile-details-parentAccount {
      flex: unset;
      align-self: stretch;
    }
  
    .frame-containerAccount {
      flex-direction: column;
    }

   
    
  }

  @media screen and (max-width: 420px) {
    .dropdown-parentAccount {
      overflow: hidden;
      width: auto;
      align-self: stretch;
      padding-inline: 25px;

    }

    .header-navigationAccount {
      padding-inline: 10px;
      padding-bottom: 10px;
    }
    .frame-containerAccount{
      padding: 0.5rem
    }
    
  }
  

  @media screen and (max-width: 600px) {
    .frame-parentAccount {
      padding-inline: 0px;
    }
    .account-parentAccount{
      display:none;
    }

  }
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    &.custom-height{
        height: 300px;
    }
    &.custom-height-auto{
      height: auto;
    }
  }
  
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #D71513;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.ProfilePCoin__Page__Container {

    width: 100%;

    .ProfilePCoin__Page__Heading {
        font-family: "Poppins"
    }

    .ProfilePCoin__Available__Coin__Card {
        border: 1px solid #D71513;
        background-color: #ffffff;
        padding: 1rem;
        display: flex;
        gap: 1.5rem;
        border-radius: 5px;
        padding: 2rem;
        margin-bottom: 1.5rem;

        .ProfilePCoin__Available__Coin__Icon {
            width: 40px;
        }

        .ProfilePCoin__Available__Coin {
            color: #D71513;
            font-family: "Poppins";
            margin-bottom: 0.1rem;
        }

        .ProfilePCoin__Available__Coin__Text {
            color: gray;
            font-family: "Poppins";
            margin: 0;
        }
    }

}
.footer-textFooterCard {
  position: relative;
  line-height: 24px;
  color: var(--grey-300, #D0D5DD);
  /* Text md/Regular */
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.social-iconFooterCard {
  position: relative;
  width: 24px;
  height: 24x;
  overflow: hidden;
  flex-shrink: 0;
  color: #D0D5DD;
  svg{
    font-size: 24px;
  }
}
.social-iconsFooterCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.social-icon5FooterCard {
  position: relative;
  width: 24px;
  height: 24px;
}
.content10FooterCard {
  align-self: stretch;
  border-top: 1px solid var(--grey-600);
  display: flex;
  flex-direction: row;
  padding: 15px 0px 0px;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-13xl);
}
.container3FooterCard {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px var(--padding-13xl);
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--grey-300);
}
.footerFooterCard {
  background-color: var(--grey-800);
  display: flex; 
  flex-direction: column;
  padding: 50px 64px 25px 64px;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--base-black);
  font-family: var(--text-md-semibold);
  margin-top: 50px;
  width: 100%;
}
.footerDivHomeFooterCard {
  margin-top: 50px;
}
@media screen and (max-width: 600px) {
  .content10FooterCard {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

@media screen and (max-width: 900px) {
  .footerFooterCard {
   padding: 30px;
  }
}

@media screen and (max-width: 700px) {
  .container3FooterCard {
   padding: 0px;
  }
}
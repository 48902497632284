.cart__page{
    &__block{
            .cart__page__tab{
                border: none;
                text-align: center;
                display: flex;
                justify-content: center;
                gap: 80px;
                margin-bottom: 40px;
                list-style: none;
                li{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: var(--grey-700, #344054);
                    position: relative;
                    /* 16px/Medium */
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    span{
                        width: 10px;
                        height: 10px;
                        background: #888686;
                        border-radius: 50%;
                        position: relative;
                        display: block;
                        &::before{
                            content: "";
                            border: 2px solid #888686;
                            height: 20px;
                            width: 20px;
                            top: -5px;
                            left: -5px;
                            border-radius: 50%;
                            position: absolute;
                        }
                    }
                    &:before{
                        content: "";
                        height: 2px;
                        width: 60px;
                        left: -72px;
                        background-color: #888686;
                        position: absolute;
                    }
                    &:first-child{
                        &::before{
                            content: none;
                        }
                    }
                    &.react-tabs__tab--selected{
                        color: #168952;
                        span{
                            &::before{
                                border-color: #168952;
                            }
                        }
                    }
                    &.active{
                        color: #168952;
                        span{
                            &::before{
                                border-color: #168952;
                            }
                        }
                    }
                    &.already-marked{
                        cursor: pointer;
                        &:before{
                            background-color: #168952;
                        }
                    }
                    svg{
                        font-size: 22px;
                        color: #168952;
                        margin-right: -5px;
                    }
                }
               
            }
            .react-tabs__tab{
                position: relative;
                border: none;
            }
    }
    &__inner__block{
        display: flex;
        gap: 30px;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    &__content__block{
        flex: 2 1 0;
        .frame-groupCartMain{
            max-width: 570px;
            margin: 0 auto;
        }
        .group-parentCartMain{
            text-align: center;
        }
        .add-items-wrapperCartMain{
            width: 100%;
        }
        .your-cart-isCartMain{
            color: var(--base-black, #1C1C1C);
            text-align: center;
            /* 20px/Medium */
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .blackOrderedItemsList{
            display: flex;
            gap: 15px;
            align-items: center;
            padding: 8px;
            span{
                font-size: 20px;
            }
        }
        .quantity__controller{
            cursor: pointer;
        }
        .product__quantity{
            color: var(--primary-d71513);
            min-width: 25px;
            text-align: center;
        }
        .frame-parent5DeliveryAddressListing{
            margin: 0 auto;
        }
        .address__empty__block{
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            h3{
                font-size: 22px;
                color: var(--base-black, #9d9d9d);
                font-family: 'Poppins';
            }
        }
        .form-check-input:checked[type=radio]{
            background: #FFFFFF;
        }
        .frame-inputDeliveryAddressListing {
            cursor: pointer;
            border-radius: var(--br-981xl);
            border: 1px solid var(--Grey---767676, #888686);
            box-sizing: border-box;
            width: 17px;
            height: 17px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: relative;
            &:focus{
                box-shadow: none;
            }
            &::before{
                position: absolute;
                content: "";
                height: 11px;
                width: 11px;
                border-radius: 50%;
                background: #FFFFFF;
                left: 2px;
                top: 2px;
            }
            &:checked{
              background-color: #FFFFFF; /* Change to your desired background color */
              border: 1px solid var(--primary-d71513); /* Change to your desired border color */
              color: #FFFFFF; /* Change text color if needed */
              &::before{
                background: var(--primary-d71513);
              }
            }
          }
          .checkbox-allOrderedItemsList{
            width: 28px;
            height: 28px;
            &:focus{
                box-shadow: none;
            }
            &:checked{
                background-color: #D71513;
                border-color: #D71513;
            }
          }
          .checkbox-baseOrderedItemsList{
            top: 17px;
            left: 16.5px;
            width: 20px;
            height: 20px;
            &:focus{
                box-shadow: none;
            }
            &:checked{
                background-color: #D71513;
                border-color: #D71513;
            }
          }

          .vendor-baseOrderedItemsList {
            cursor: pointer;
            margin: 0 !important;
            border-radius: var(--br-7xs);
            background-color: var(--m3-sys-light-on-primary);
            border: 1px solid var(--grey-300);
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            overflow: hidden;
            flex-shrink: 0;
            z-index: 1;
            border-radius: 50% !important;
        }
    }
    &__sidebar__block{
        flex: 1 1 0;
        min-width: 440px;
    }
}
// Modals style
.remove__modal{
    min-height: 150px;
    
    .button--group{
        justify-content: center;
        button{
            width: 100px;
        }
    }
    .modal-footer,
    .modal-header{
        border: none;
    }
    .remove__primary__btn{
        display: flex;
        padding: 12px 20px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 8px;
        background: var(--Primary---D71513, #D71513);
        color: #FFF;

        /* 14px/Medium */
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .remove__secondary__btn{
        display: flex;
        padding: 12px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #3A3A3A;
        color: var(--base-black, #1C1C1C);

        /* 14px/Medium */
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.addrees__modal{
    .form-check-input:focus,
    .form-control:focus{
        box-shadow: none;
        border-color: #D71513;
    }
    .form-check-input:checked{
        background-color: #D71513;
        border-color: #D71513;
    }
    
    div[class^="-control"]{
        &:hover{
            border-color: #D71513;
        }
    }
    .primary__btn{
        margin-top: 30px;
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--Primary---D71513, #D71513);
        width: 100%;
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--base-white, #FFF);

        /* Text md/SemiBold */
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }
    .modal-footer,
    .modal-header{
        border: none;
    }
    button.close{
        font-size: 25px;
        color: var(--base-black, #1C1C1C);
    }
}
// .voucher__modal__block{

// }
.voucher__content__block{
    margin-left: 30px;
}
.PCoinRedeem__modal{
    .modal-header{
        padding: 0;
        button.close{
            margin: 0;
            line-height: 30px;
        }
    }
    &__block{
        display: flex;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        border-radius: 8px;
        background: #FFF;
        &--img{
            width: 124px;
            height: 124px;
            img{
                max-width: 100%;
            }
        }
        h3{
            color: var(--base-black, #1C1C1C);
            text-align: center;

            /* 24px/Semibold */
            font-family: 'Poppins';
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0;
        }
        &--content{
            color: var(--base-black, #1C1C1C);
            text-align: center;

            /* 18px/Regular */
            font-family: 'Poppins';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
        }
        &--coins{
            display: flex;
            gap: 10px;
            align-items: center;
            p{
                margin-bottom: 0;
                color: var(--Primary---D71513, #D71513);

                /* 20px/Semibold */
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        &--error--content{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 13px;
        }
    }
}

.voucher__item{
    position: relative;
    width: 100%;
    min-height: 170px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid var(--gray-100, #F5F5F5);
    background: #FFF;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.06);
    padding: 16px 16px 16px 74px;
    position: relative;
    .voucher__bg{
        position: absolute;
        height: 100%;
        transform: rotate(0deg);
        left: 0;
        top: 0;
    }
    &__title__block{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        h3{
            color: var(--gray-800, #262626);

            /* 16px/Medium */
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0;
        }
        button{
            color: var(--Primary---D71513, #D71513);
            text-align: right;

            /* 14px/Medium */
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    &__subTitle{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
        margin-bottom: 45px;
        color: var(--gray-500, #737373);

        /* 12px/Regular */
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &__details__block{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        p{
            color: var(--gray-500, #737373);
            margin-bottom: 0;
            /* 12px/Regular */
            font-family: 'Poppins';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        button{
            color: var(--Primary---D71513, #D71513);
            /* 14px/Regular */
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 5px 0;
        }
    }
    &__dicount__block{
        transform: rotate(-90deg);
        color: #FFF;
        text-align: center;

        /* 16px/Bold */
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: absolute;
        left: -2px;
        top: 44%;
        margin: 0;
    }
    &__notfound__block{
        max-width: 570px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        margin-top: 80px;
        h3{
            color: var(--base-black, #1C1C1C);
            text-align: center;

            /* 20px/Medium */
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        p{
            color: var(--Grey---767676, #888686);
            text-align: center;

            /* 16px/Regular */
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    &__back__btn{
        display: flex;
        width: 164px;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary---D71513, #D71513);
        background: var(--base-white, #FFF);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Primary---D71513, #D71513);

        /* Text sm/SemiBold */
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }
}

.voucher__item + .voucher__item{
    margin-top: 10px
}

@media screen and (max-width: 960px) {
    .cart__page{
        &__inner__block{
            flex-direction: column;
        }

        &__content__block{
            flex: auto;
            width: 100%;
            .add-more-items-wrapperOrderedItemsList{
                width: 100%;
            }
        }

        &__sidebar__block{
            min-width: auto;
            flex: auto;
            width: 100%;
            .frame-parent3BillContainer{
                width: 100%;
                padding: 0;
                border-color: transparent;
            }
            .mask-group-parentBillContainer{
                width: 100%;
                .p-coin-parentBillContainer{
                    margin-left: 0;
                }
            }
            .mask-group-parentBillVoucherForm,
            .you-can-earn-50-p-coins-for-co-parent{
                width: 100%;
                
            }
            .frame-parent17BillVoucherForm{
                padding: 0;
                width: 100%;
                border-color: transparent;
            }
            .you-can-earn{
                font-size: 14px;
            }
        }
  }
}

@media screen and (max-width: 600px) {
    .cart__page{
        &__block{
            .cart__page__tab{
                .react-tabs__tab-list{
                    gap: 30px;
                    li{
                        &::before{
                            width: 35px;
                            left: -36px;
                        }
                    }
                }
            } 
        }
    }
    .voucher__Modal{
        .modal-header{
            width: 100%;
        }
        .modal-body{
            width: 100%;
        }
    }
    .voucher__item{
        width: 100%;
        &__subTitle{
            margin-bottom: 20px;
        }
    }
}

.cart{
    &__payment{
        &__option{
            &__block{
                width: 100%;
                margin-top: 30px;
                h3{
                    color: var(--grey-800, #1D2939);
                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            &__wrap{
                display: flex;
                height: 53px;
                padding: 12px;
                width: 100%;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                background: var(--grey-50, #F9FAFB);
                .form-check{
                    width: 100%;
                    .form-check-input{
                        border-radius: 50%;
                    }
                }
                .form-check-label{
                    width: 100%;
                }
            }
            &__content{
                &__block{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    p{
                        margin-bottom: 0;
                        font-size: 16px;
                    }
                    img{
                        height: 40px;
                        width: 80px;
                    }
                }
            }
        }
    }
    &__einvoice{
        &__content{
            font-size: 12px;
        }
    }
}

.orderPlace{
    &__success{
        &__Modal{
            .modal-header{
                position: absolute;
                top: 50%;
                right: -190px;
                left: 0;
                width: 150px;
                margin: 0 auto;
                transform: translateY(-245px);
                z-index: 99;
                button{
                    font-size: 30px;
                }
            }
            .modal-body{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img{
                    height: 350px;
                }
            }
            &__content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h3{
                    color: #168952;
                    text-align: center;

                    /* 24px/Semibold */
                    font-family: 'Poppins';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 25px;
                }
                h3.login__modal__message{
                    color: #D71513;
                    margin-bottom: 10px;
                }
                p{
                    color: var(--base-black, #1C1C1C);
                    text-align: center;
                    
                    /* 16px/Regular */
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                button{
                    border-radius: 8px;
                    border: 1px solid var(--Primary---D71513, #D71513);
                    background: var(--base-white, #FFF);
                    width: 164px;
                    padding: 8px 14px;
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: var(--Primary---D71513, #D71513);
                    font-family: 'Poppins';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .cart__page{
        &__block{
            .cart__page__tab{
                .react-tabs__tab-list{
                    gap: 5px;
                    li{
                        font-size: 14px;
                        &::before{
                            width: 14px;
                            left: -12px;
                            top: 16px;
                        }
                    }
                }
            } 
        }
    }
    .orderPlace{
        &__success{
            &__Modal{
                img{
                    max-width: 100%;
                }
                &__content{
                    h3{
                        font-size: 19px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .cart__page{
        &__content__block{
            .frame-parent14OrderedItemsList{
                flex-direction: column;
            }
            .frame-parent13OrderedItemsList{
                flex-direction: column;
                gap: 1px;
            }
        }
        
    }
}
@media screen and (max-width:420px){
    .cart__page__tab{
        gap: 36px !important;
        & li:before {
                content: "";
                height: 2px;
                width: 28px !important;
                left: -31px !important;
                background-color: #888686;
                position: absolute;
            }
    }
}
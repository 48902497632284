.footer-text {
    flex: 1;
    position: relative;
    line-height: 24px;
  }
  .vector-icon {
    position: absolute;
    height: 99.39%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0.61%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .social-icon {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .group-icon {
    position: absolute;
    height: 99.98%;
    width: 99.94%;
    top: 0%;
    right: 0.06%;
    bottom: 0.02%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .vector-icon1 {
    position: absolute;
    height: 100%;
    width: 69.51%;
    top: 0%;
    right: 15.23%;
    bottom: 0%;
    left: 15.25%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .group-icon1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .vector-icon2 {
    position: absolute;
    height: 81.25%;
    width: 100%;
    top: 9.37%;
    right: 0%;
    bottom: 9.37%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .social-icon5 {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 24px;
    height: 24px;
  }
  .social-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .content11 {
    align-self: stretch;
    border-top: 1px solid var(--grey-600);
    display: flex;
    flex-direction: row;
    padding: var(--padding-13xl) 0px 0px;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .container3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0px var(--padding-13xl);
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-300);
    font-family: var(--text-md-semibold);
  }
  

.logo-1-icon {
   
    width: 70%;
    overflow: hidden;
   
  }
  .logo-2-icon{
      width: 70%;
    
  }
  
  .icon4 {
    position: relative;
    height: 30%;
    max-width: 100%;
    object-fit: cover;
    align-self: stretch;
    flex-shrink: 0;
  }
  
  .welcome-back-glad {
    align-self: stretch;
    position: relative;
    font-weight: 600;
    color: var(--Primary---D71513, #D71513);
    text-align: center;
    /* 24px/Semibold */
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .hurray-your-password {
    color: var(--base-black, #1C1C1C);
    text-align: center;
    /* 16px/Regular */
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .text6 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  
  .password__changed__button {
    border-radius: 15px;
    background-color: var(--primary-d71513);
    color: white;
    font-size: 16px;
    border: none;
    width: 100%;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
  .buttons{
    color:#ffffff;
    background-color: #D71513;
    padding: 0.5rem 1rem;
    margin-top:1rem ;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    width:100%;

  }
  
  .logo {
    width:50%;
  
  }
  
  .reset__password__successfully__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    margin: 0 auto;
  }
  .reset-password {
    position: relative;
    background-color: var(--base-white);
    width: 100%;
    height: 960px;
    display: flex;
    flex-direction: column;
    // padding: var(--padding-77xl) var(--padding-13xl) var(--padding-29xl);
    padding:1rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: var(--display-sm-semibold-size);
    color: var(--grey-900);
    font-family: var(--text-sm-semibold);
  }
  
  @media  screen and (max-width: 420px) {
    .reset-password {
    padding: var(--padding-13xl) ;
  
    }
  
    .content {
      gap: none;
    }
  }
  
.catalogueProductsContainerMain {
  // position: relative;
  // display: flex;
  // flex-wrap: wrap;
  // gap: 25px;
  // align-items: center;
  // padding-bottom: 60px;
  // justify-content: stretch;
  // min-height: 300px;
  // width: 100%;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(223px, 1fr));
  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  width: 100%;
  .catalouge__product__item {
    flex-basis: 23%;
    box-sizing: border-box;
  }
}
.catalouge__product__item {
  width: 100%;
  height: 357px;

}

.cataloguePaginationComponent {
  // margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  list-style: none;
  padding-left: 0;
  .previous {
    border: 1px solid black;
    border-radius: 8px;
    padding-inline: 13px;
    padding-block: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    a {
      color: var(--base-black, #1c1c1c);
      /* 16px/Regular */
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      transition: all 0.3s ease-in;
    }
    &:hover {
      border: 1px solid #d71513;
      a {
        color: #d71513;
      }
    }
  }
  .next {
    border: 1px solid black;
    border-radius: 8px;
    padding-inline: 13px;
    padding-block: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    a {
      color: var(--base-black, #1c1c1c);
      /* 16px/Regular */
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      transition: all 0.3s ease-in;
    }
    &:hover {
      border: 1px solid #d71513;
      a {
        color: #d71513;
      }
    }
  }

  li {
    a {
      color: var(--base-black, #1c1c1c);
      /* 16px/Regular */
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      transition: all 0.3s ease-in;
    }
    &:hover {
      a {
        color: #d71513 !important;
      }
    }
    &.selected {
      a {
        color: #d71513 !important;
      }
    }
  }
}

.PaginationLi:hover {
  background-color: #d71513;
  padding: 5px;
  color: white;
}

.paginationMainFlexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.paginationMainFlexDiv.mob {
  margin-top: 20px;
  justify-content: center;
}

.catalouge__item__notfound__block {
  text-align: center;
  width: 100%;
  img {
    margin-bottom: 50px;
    max-width: 100%;
  }
  h3 {
    color: var(--Primary---D71513, #d71513);
    text-align: center;

    /* 24px/Semibold */
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: var(--base-black, #1c1c1c);
    text-align: center;

    /* 16px/Regular */
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media screen and (max-width: 420px) {
  .catalogueProductsContainerMain {
    padding: 0px;
  }

  .paginationMainFlexDiv {
    padding: 0px;
  }

  .paginationMainFlexDiv.mob {
    padding-inline: 20px;
  }
}

.catalouge__product__item {
  width: 100%;
  height: auto !important;

}
@media screen and (max-width: 1180px) {
  .catalouge__product__item {
    width: 100%;
    height: 335px !important;
  
  }
}

@media screen and (max-width:520px){
  .catalouge__product__item {
    width: 100%;
    height: 315px;
  
  }
}
@media screen and (max-width: 450px) {
  .catalogueProductsContainerMain {
    grid-gap: 10px !important;
  }
  .catalouge__product__item {
    width: 100%;
    height: 335px !important;
  
  }
  
}
@media screen and (max-width: 400px) {
  .catalouge__product__item {
    width: 100%;
    height: auto !important;
  
  }
}

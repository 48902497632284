.checkbox-base-iconOrderedItemsList {
    position: relative;
    border-radius: var(--br-7xs);
    width: 28px;
    height: 28px;
    overflow: hidden;
    flex-shrink: 0;
}

.items-selectedOrderedItemsList {
    position: relative;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.checkbox-base-parentOrderedItemsList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-mini);
}
.OrderedItem__Inner__Container{
    display:flex;
    align-items: center;
    gap: 1rem;
}
.rectangle-divOrderedItemsList {
    position: relative;
    background-color: var(--color-gainsboro);
    border: 1px solid var(--border-ededed);
    box-sizing: border-box;
    width: 119.05px;
    height: 119.05px;
}

.rectangle-wrapperOrderedItemsList {
    width: 124.01px;
    height: 124.01px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // background-image: url("");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.hp-pavilion-14-dv2029tuOrderedItemsList {
    position: relative;
    font-weight: 600;
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
    text-transform: capitalize;
    text-decoration: none;
    color:#000000;
}

.colorTitleOrderedItemsList {
    font-weight: 600;
}

.divOrderedItemsList {
    position: relative;
    text-decoration: line-through;
}

.rm290900OrderedItemsList {
    position: relative;
    font-weight: 500;
    color: var(--base-black);
}

.offOrderedItemsList {
    position: relative;
    color: var(--color-orangered);
}

.parentOrderedItemsList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    font-size: var(--m3-label-large-size);
    color: var(--grey-767676);
}

.hp-pavilion-14-dv2029tu-i5-123-parentOrderedItemsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--px-medium-size);
}

.colorOrderedItemsList {
    position: relative;
    font-weight: 500;
}

.frame-child2OrderedItemsList {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-darkslategray-100);
    width: 17px;
    height: 17px;
}

.blackOrderedItemsList {
    position: relative;
    padding: 0.5rem;
}

.ellipse-parentOrderedItemsList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    color: var(--color-dimgray-100);
}

.color-parentOrderedItemsList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-3xs);
}

.sizeOrderedItemsList {
    position: relative;
    font-weight: 500;
    display: inline-block;
    width: 40px;
    flex-shrink: 0;
}

.gbOrderedItemsList {
    position: relative;
    color: var(--color-dimgray-100);
}

.frame-parent13OrderedItemsList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    height: fit-content;
}

.vector-iconOrderedItemsList {
    position: relative;
    border-radius: var(--br-12xs);
    width: 8px;
    height: 3.68px;
}

.quantity-1-parentOrderedItemsList {
    background-color: var(--color-whitesmoke-100);
    height: 24px;
    display: flex;
    flex-direction: row;
    padding: 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: var(--gap-2xs);
}

.remove1OrderedItemsList {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-size: var(--m3-label-large-size);
    text-decoration: underline;
    font-family: var(--text-md-semibold);
    color: var(--color-crimson);
    text-align: left;
    display: inline-block;
}

.frame-child3OrderedItemsList {
    position: relative;
    width: 1px;
    height: 21.5px;
}

.move-to-wishlistOrderedItemsList {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-size: var(--m3-label-large-size);
    text-decoration: underline;
    font-family: var(--text-md-semibold);
    color: var(--base-black);
    text-align: right;
    display: inline-block;
}

.remove-parentOrderedItemsList {
    align-self: stretch;
    border-top: 1px solid var(--border-ededed);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) 0px 0px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
}

.frame-parent12OrderedItemsList {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
    z-index: 1;
}

.checkbox-base-icon1OrderedItemsList {
    position: absolute;
    margin: 0 !important;
    top: 15px;
    left: 15.5px;
    border-radius: var(--br-7xs);
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.frame-sectionOrderedItemsList {
    align-self: stretch;
    background-color: var(--m3-sys-light-on-primary);
    border: 1px solid var(--border-ededed);
    display: flex;
    flex-direction: row;
    padding: var(--padding-sm);
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-3xs);
    text-align: left;
    font-size: 14px;
    color: var(--base-black);
    font-family: var(--text-md-semibold);
}

.colorDivOrderedItemsList {
    width: 17px;
    height: 17px;
    background: black;
    border-radius: 50%;
}

.coloringDivOrderedItemsList {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    p {
        margin-bottom: 0;
    }
}

.hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList {
    position: relative;
    width: 122.92px;
    height: 122.92px;
    object-fit: cover;
}

.hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList {
    background-color: var(--color-gainsboro);
    border: 1px solid var(--border-ededed);
    box-sizing: border-box;
    width: 125px;
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    cursor: pointer;
    text-decoration: none;
}

.checkbox-baseOrderedItemsList {
    cursor: pointer;
    position: absolute;
    margin: 0 !important;
    top: 15px;
    left: 15.5px;
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    border: 1px solid var(--grey-300);
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}

.checkbox-allOrderedItemsList {
    cursor: pointer;
    border-radius: var(--br-7xs);
    border: 1px solid var(--grey-300);
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}


.remove2OrderedItemsList {
    position: relative;
    text-decoration: underline;
    cursor: pointer;
}

.move-to-wishlist1OrderedItemsList {
    position: relative;
    text-decoration: underline;
    color: var(--base-black);
    text-align: right;
    cursor: pointer;
}

.remove-groupOrderedItemsList {
    align-self: stretch;
    border-top: 1px solid var(--border-ededed);
    display: flex;      
    flex-direction: row;
    padding: var(--padding-3xs) 0px 0px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);   
    color: var(--color-crimson);
}

.frame-parent15OrderedItemsList {
    flex: 1;
    display: flex;
    flex-direction: column;        
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2; 
}

.frame-parent14OrderedItemsList {
    align-self: stretch;
    background-color: var(--m3-sys-light-on-primary);
    border: 1px solid var(--border-ededed);
    display: flex;
    flex-direction: row;
    padding: var(--padding-sm);
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-3xs);
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    color:#000000;
}

.frame-parent14OrderedItemsList>div {
    width: fit-content;
    padding: 0px;

}

.add-more-items-wrapperOrderedItemsList {
    border-radius: var(--br-8xs);
    background-color: var(--m3-sys-light-on-primary);
    border: 1px solid var(--grey-767676);
    box-sizing: border-box;
    width: 100%;
    height: 61px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-sm) var(--padding-5xl);
    align-items: center;
    justify-content: flex-start;
    font-size: var(--px-medium-size);
    color: var(--primary-d71513);
}

.frame-parent11OrderedItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // gap: var(--gap-sm);
    text-align: left;
    font-size: var(--px-medium1-size);
    color: var(--base-black);
    font-family: var(--text-md-semibold);
    margin: 0 auto;
}
@media screen and (max-width:800px){
    .OrderedItem__Inner__Container{
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
}
@media screen and (max-width: 380px) {
    .items-selectedOrderedItemsList {
        font-size: 18px;
    }
    .hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList{
     width:40% !important;
     height: 50% !important;
    }
    .hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList{
    width: 100% !important;
    }
}

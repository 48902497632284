.divider {
    position: relative;
    background-color: var(--grey-200);
    width: 1440px;
    height: 1px;
  }
  .header-navigation {
    align-self: stretch;
    background-color: var(--base-white);
    border-bottom: 1px solid var(--grey-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home-child {
    align-self: stretch;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .home {
    position: relative;
    background-color: var(--base-white);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
.avatar-icon1EditProfile {
    position: relative;
    border-radius: var(--br-181xl);
    width: 90px;
    height: 90px;
  }
  .label3EditProfile {
    position: relative;
    font-size: var(--m3-label-large-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: left;
  }
  .avatar-parentEditProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
.ToastStyle{
  background-color: green;
  color:#ffffff;
  padding: 2rem;
}
  .avatar-imageDiv {
      width: 90px;
      height: 90px;
      background: #F2F4F7;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
  }
  .avatar-image{
    width: 100%;
    height: inherit;
    border-radius: 50%;
    overflow: hidden;
  }

  .dropdown-iconEditProfile {
    width: 32px;
    height: 32px;
  }

  .dropdown1EditProfile {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .label4EditProfile {
    position: relative;
    font-size: var(--m3-label-large-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--grey-700);
    text-align: left;
  }

  .codeEditProfile {
    border: 1px solid var(--grey-300);
    font-family: var(--text-md-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--m3-sys-light-on-primary);
    width: 35px;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding-block: 10px;
    padding-left: 10px;
    padding-right: 5px;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }


  .phoneDivEditProfile {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .input3EditProfile {
    border: 1px solid var(--grey-300);
    font-family: var(--text-md-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--m3-sys-light-on-primary);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    outline: none;
    flex: 1;
  }

  .input3EditProfile.phoneEditProfile {
    padding-block: 10px;
    padding-left: 3rem;
    // border-left: none;
    // border-top-left-radius: 0px;
    // border-bottom-left-radius: 0px;
  }
  .DefaultCodeText{
      position: absolute;
        top: 10px;
        color: gray;
        left: 7px;
        font-size: 17px;
  }

  .input3EditProfile.phoneEditProfile:hover {
    // box-shadow: none;
    // border-left: none;
  }

  .input3EditProfile:hover {
    border: 1px solid var(--primary-300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dce8ff;
  }

  .hint-text3EditProfile {
    align-self: stretch;
    position: relative;
    font-size: var(--m3-label-large-size);
    line-height: 20px;
    font-family: var(--text-md-semibold);
    color: var(--grey-600);
    text-align: left;
    display: flex;
    gap: 20px;

  }
  .input-fieldEditProfile {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .formEditProfile {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .check-iconEditProfile {
    position: relative;
    width: 22px;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text29EditProfile {
    position: relative;
    font-size: var(--text-md-semibold-size);
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: left;
  }
  .input7EditProfile {
    flex: 1;
    height: fit-content;
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--primary-d71513);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .text30EditProfile {
    position: relative;
    font-size: var(--text-md-semibold-size);
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--grey-767676);
    text-align: left;
  }
  .input8EditProfile {
    cursor: pointer;
    border: 1px solid var(--grey-300);
    padding: var(--padding-3xs) var(--padding-sm);
    background-color: var(--m3-sys-light-on-primary);
    flex: 1;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .input-parentEditProfile {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .dropdown-groupEditProfile {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .label-text2EditProfile {
    position: relative;
    font-weight: 600;
    color: white;
    padding-block: 10px;
  }
  .state-layer2EditProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .button1EditProfile {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    font-family: Poppins, sans-serif;
    
  }

  .button1EditProfile:hover {
    box-shadow: var(--shadow-base);
    
  }

  .frameparent5EditProfile {
    width:95%;
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    padding: var(--padding-13xl) var(--padding-45xl);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    text-align: center;
    font-size: var(--m3-label-large-size);
    color: var(--m3-sys-light-on-primary);
    font-family: var(--text-md-semibold);
  }

  .frameparent5EditProfile-e-invoice {
    width:95%;
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    padding: var(--padding-13xl) var(--padding-45xl);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1px;
    text-align: center;
    font-size: var(--m3-label-large-size);
    color: var(--m3-sys-light-on-primary);
    font-family: var(--text-md-semibold);

    .title {
      font-size: 24px;
    }

    .input3EditProfile {
      width: 100% !important;
    }
  }

    @media screen and (max-width: 576px) {
  .frameparent5EditProfile-e-invoice {
    padding: var(--padding-13xl) var(--padding-13xl);
    }
    }
  

  .input9EditProfile {
    border: 1px solid var(--grey-300);
    font-family: var(--text-sm-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    outline: none;
    flex: 2;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
 
.prefix-phoneProfile {
  width: 50px;
  border: 1px solid var(--grey-300) ;
  border-right: none;
  font-family: var(--text-sm-semibold);
  font-size: var(--text-md-semibold-size);
  background-color: var(--base-white);
  /* align-self: stretch; */
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-right: 0px;
  padding-left: var(--padding-3xs) ;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  border-top-right-radius: 0px;
 border-bottom-right-radius: 0px;
  /* flex: 1; */

}

.phone-divProfile {
  display: flex;
  gap: 0px;
  width: 100%;
}
.Delete__Btn__Container{
  display: flex;
  align-items: center;
  gap:0.3rem;
  cursor: pointer;
  margin:-0.3rem auto;
  .Delete__Btn__Text{
    color:#D71513;
    font-weight: 500;
    font-family: "Poppins";
  }
}


// for profile image

.selectedProfileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.phoneInput__And__Button{
width: 100%;
text-align: right;
}
.Phone__Input__Btn{
  color: #ffffff;
  background-color: #D71513;
  padding: 0.5rem;
  border-radius: 5px;
  font-family: "Poppins";
  cursor: pointer;
  margin-top:0.4rem;
  margin-right: auto;
}


@media screen and (max-width: 600px) {
  .frameparent5EditProfile {
    padding: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.logo-1-icon1Reset {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 48px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .text8Reset {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  .label-textReset {
    align-self: stretch;
    position: relative;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-767676);
  }
  .text-parentReset {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .headerReset {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .label1Reset {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-sm-semibold);
    color: var(--grey-700);
    text-align: left;
  }
  .input4Reset {
    border: 1px solid var(--grey-300);
    font-family: var(--text-sm-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
  }
  .hint-text1Reset {
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    color: var(--grey-600);
    text-align: left;
    display: none;
  }
  .input-fieldReset {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .formReset {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .icon7Reset {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text9Reset {
    position: relative;
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: var(--base-white);
    text-align: left;
  }
  .buttons1Reset {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .actions1Reset {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .remember-passwordReset {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    color: var(--grey-500);
    text-align: left;
  }
  .text10Reset {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--text-sm-semibold);
    color: var(--primary-d71513);
    text-align: left;
  }
  .buttons2Reset {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .rowReset {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
  }
  .content2Reset {
    align-self: stretch;
    border-radius: var(--br-xs);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .contentReset {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    margin-inline: auto;
    background-color: #ffffff;
    padding: 2rem;
  }
  .reset-passwordReset {
    position: relative;
    background-image: url("../../../../images/popUpBackground.svg");
    width: 100%;
    height: 960px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-77xl) var(--padding-13xl) var(--padding-29xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: var(--display-sm-semibold-size);
    color: var(--grey-900);
    font-family: var(--text-sm-semibold);
  }
  
  @media screen and (max-width: 420px) {
    .rowReset {
      flex-direction: column;
      gap: var(--gap-9xs);
      align-items: center;
      justify-content: center;
    }
  }
  

  .label {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  .search-md-icon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text4 {
    position: relative;
    line-height: 24px;
  }
  .content2,
  .input,
  .input-with-label {
    display: flex;
    justify-content: flex-start;
  }
  .content2 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
  }
  .input,
  .input-with-label {
    align-self: stretch;
  }
  .input {
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--grey-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-500);
  }
  .input-with-label {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  .hint-text {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--grey-600);
    display: none;
  }
  .input-dropdown {
    align-self: stretch;
    width: 574px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .content1,
  .nav-item-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .content1 {
    align-items: center;
    gap: var(--gap-45xl);
  }
  .nav-item-button {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    overflow: hidden;
    padding: var(--padding-3xs);
    align-items: flex-start;
  }
  .heart-icon {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .nav-item-button1 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    width: 40px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .notifications-icon {
    position: relative;
    width: 21.5px;
    height: 21.5px;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
  }
  .avatar-icon {
    position: relative;
    border-radius: 200px;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  // .dropdown {
  //   width: 40px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  // }
  .container,
  .content3 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .content3 {
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .container {
    width: 1280px;
    padding: var(--padding-base) 0;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .divider {
    align-self: stretch;
    position: relative;
    background-color: var(--grey-200);
    height: 1px;
  }
  .dot-icon1 {
    position: relative;
    width: 10px;
    height: 10px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text5 {
    position: relative;
    font-weight: 500;
  }
  .content4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .text6 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  .badge1 {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--grey-700);
  }
  .chevron-down-icon1 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .nav-item-base {
    border-radius: var(--br-7xs);
    background-color: var(--grey-50);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .bar-chart-01-icon1 {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .badge2 {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
  }
  .nav-item-base1,
  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .nav-item-base1 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    overflow: hidden;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    gap: var(--gap-5xs);
  }
  .navigation {
    align-items: flex-start;
    gap: var(--gap-9xs);
    color: var(--grey-700);
  }
  .container1,
  .header-navigation {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .container1 {
    width: 1280px;
    flex-direction: row;
    padding: var(--padding-base) 0;
    gap: 120px;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-900);
  }
  .header-navigation {
    background-color: var(--m3-sys-light-on-primary);
    border-bottom: 1px solid var(--grey-200);
    width: 1440px;
    flex-direction: column;
  }
  .frame-childCartMain {
    position: relative;
    width: 101px;
    height: 98px;
  }
  .add-items-toCartMain,
  .your-cart-isCartMain {
    position: relative;
    display: inline-block;
    font-weight: 500;
  }

  .add-items-toCartMain {
    font-size: var(--text-md-semibold-size);
    color: var(--grey-767676);
  }
  .group-parentCartMain,
  .your-cart-is-empty-parentCartMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .group-parentCartMain {
    align-items: center;
    gap: var(--gap-13xl);
  }
  .add-itemsCartMain {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: left;
    padding-left: 24px;
    padding-block: 17px;
  }
  .add-items-wrapperCartMain,
  .frame-groupCartMain {
    display: flex;
    box-sizing: border-box;
    align-items: center;
  }
  .add-items-wrapperCartMain {
    cursor: pointer;
    border: 1px solid var(--grey-767676);
    padding: var(--padding-sm) var(--padding-5xl);
    background-color: var(--m3-sys-light-on-primary);
    border-radius: var(--br-8xs);
    height: 61px;
    flex-direction: row;
    justify-content: flex-start;
  }
  .frame-groupCartMain {
    flex-direction: column;
    padding: var(--padding-45xl) 0 0;
    justify-content: center;
    gap: var(--gap-45xl);
  }
  .cart-innerCartMain,
  .frame-parentCartMain {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
  }
  .frame-parentCartMain {
    flex-direction: row;
    justify-content: center;
    gap: var(--gap-5xl);
  }
  .cart-innerCartMain {
    flex-direction: column;
    padding: var(--padding-xs) 80px var(--padding-13xl);
    justify-content: flex-start;
    text-align: center;
    font-size: var(--px-semibold-size);
    color: var(--base-black);
    font-family: var(--text-md-semibold);
  }
  .supporting-text {
    position: relative;
    line-height: 24px;
    display: inline-block;
  }
  .logo-and-supporting-text {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .text15 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
  }
  .buttons1,
  .footer-link {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .buttons1 {
    border-radius: var(--br-5xs);
    overflow: hidden;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .footer-link {
    justify-content: flex-start;
  }
  .footer-links,
  .logo-and-links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .footer-links {
    align-self: stretch;
    flex-direction: row;
  }
  .logo-and-links {
    flex: 1;
    flex-direction: column;
  }
  .heading {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  .mobile-app-store-badge,
  .mobile-app-store-badge1 {
    position: relative;
    border-radius: var(--br-6xs);
    width: 135px;
    height: 40px;
  }
  .mobile-app-store-badge1 {
    border-radius: var(--br-8xs);
    overflow: hidden;
    flex-shrink: 0;
  }
  .actions1,
  .app-store-actions,
  .container2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .actions1 {
    align-items: center;
    gap: var(--gap-base);
  }
  .app-store-actions,
  .container2 {
    align-items: flex-start;
  }
  .app-store-actions {
    gap: var(--gap-base);
    color: var(--m3-sys-light-on-primary);
  }
  .container2 {
    width: 1280px;
    padding: 0 var(--padding-13xl);
    box-sizing: border-box;
  }
  .footer-text {
    flex: 1;
    position: relative;
    line-height: 24px;
  }
  .social-icon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .social-icons {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xl);
  }
  .cartCartMain,
  .container3,
  .content10,
  .footer,
  .social-icons {
    display: flex;
    justify-content: flex-start;
  }
  .content10 {
    align-self: stretch;
    border-top: 1px solid var(--grey-600);
    flex-direction: row;
    padding: var(--padding-13xl) 0 0;
    align-items: center;
    gap: var(--gap-13xl);
  }
  .cartCartMain,
  .container3,
  .footer {
    flex-direction: column;
  }
  .container3 {
    padding: 0 var(--padding-13xl);
    align-items: flex-start;
    color: var(--grey-300);
  }
  .cartCartMain {
    text-align: left;
    font-family: var(--text-md-semibold);
  }
 
  .cartCartMain {
    position: relative;
    background-color: var(--m3-sys-light-on-primary);
    width: 100%;
    align-items: flex-start;
    font-size: var(--m3-label-large-size);
    color: var(--grey-700);
  }



  @media screen and (max-width: 960px) {
    .frame-parentCartMain {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .add-items-wrapperCartMain {
        width: 400px;
    }
  }


  @media screen and (max-width: 420px) {
    .add-items-wrapperCartMain {
        width: 320px;
    }
  }

  
.related-productsRelatedProductsSection {
    margin: 0;
    position: relative;
    font-size: 32px;
    font-weight: 500;
    font-family: inherit;
  }
  .canon-e3370-rd-printer-photo2-icon8RelatedProductsSection {
    position: relative;
    border-radius: var(--br-8xs) var(--br-8xs) 0px 0px;
    width: 220px;
    height: 210px;
    object-fit: cover;
  }
  .canon-pixma-e3370r1RelatedProductsSection {
    align-self: stretch;
    position: relative;
    font-weight: 500;
  }
  .rm399001RelatedProductsSection {
    position: relative;
  }
  .rm345001RelatedProductsSection {
    position: relative;
    font-weight: 500;
    color: var(--base-black);
  }
  .off2RelatedProductsSection {
    position: relative;
    color: var(--color-orangered-100);
  }
  .rm39900-groupRelatedProductsSection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .p-coin-icon4RelatedProductsSection {
    position: relative;
    width: 16px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .you-can-earn-containerRelatedProductsSection {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-10xs);
  }
  .frame-parent9RelatedProductsSection {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--px-medium-size);
    color: var(--grey-767676);
  }
  .canon-pixma-e3370r-aio-wifi-in-groupRelatedProductsSection {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-wrapper7RelatedProductsSection {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xs) var(--padding-mini) var(--padding-mini);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .icon4RelatedProductsSection {
    position: relative;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text17RelatedProductsSection {
    position: relative;
    font-weight: 500;
  }
  .icon5RelatedProductsSection {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .buttons1RelatedProductsSection {
    flex: 1;
    border-radius: var(--br-8xs);
    background-color: var(--primary-d71513);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--primary-d71513);
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .heart-icon2RelatedProductsSection {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .heart-wrapperRelatedProductsSection {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .buttons-parentRelatedProductsSection {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 0px var(--padding-mini) var(--padding-mini);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
    color: var(--base-white);
  }
  .canon-e3370-rd-printer-photo2-containerRelatedProductsSection {
    border-radius: var(--br-8xs);
    background-color: var(--base-white);
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.12);
    width: 220.8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-parent8RelatedProductsSection {
    align-self: stretch;
    height: 359px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    font-size: var(--text-sm-medium-size);
  }
  .frame-parent10RelatedProductsSection {
    align-self: stretch;
    height: 359px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .related-products-parentRelatedProductsSection {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    text-align: center;
    color: var(--base-black);
    font-family: var(--text-sm-medium);
  }
  

  .relatedProductDivMainFlex {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    align-items: center;
    padding-bottom: 60px;
    justify-content: center;
  }
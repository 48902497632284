.logo-1-icon2 {
    position: relative;
    width: 208px;
    height: 48px;
    object-fit: cover;
  }
  .text16 {
    align-self: stretch;
    position: relative;
    font-size: var(--display-sm-semibold-size);
    line-height: 38px;
    font-weight: 600;
    font-family: var(--text-sm-semibold);
    color: var(--grey-900);
    text-align: center;
  }
  .label-text1 {
    align-self: stretch;
    position: relative;
    font-size: var(--text-md-semibold-size);
    font-family: var(--text-sm-semibold);
    color: var(--grey-767676);
    text-align: center;
  }
  .text-group {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-sm);
  }
  .header2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .label3 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-sm-semibold);
    color: var(--grey-700);
    text-align: left;
  }
  .input8 {
    border: 1px solid var(--grey-300);
    font-family: var(--text-sm-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    outline: none;
    flex: 1;
  }
  
  .input8:hover {
    border: 1px solid var(--primary-300);
    /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dce8ff; */
  
  }
  
  .input8.phone {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    flex: 1;
   
  }
  
  .prefix-phone {
    width: 50px;
    border: 1px solid var(--grey-300) ;
    border-right: none;
    font-family: var(--text-sm-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    /* align-self: stretch; */
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding-right: 0px;
    padding-left: var(--padding-3xs) ;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
    /* flex: 1; */
  
  }
  
  .phone-div {
    display: flex;
    gap: 0px;
    width: 100%;
  }
  
  
  .hintpassSignIn {
    /* align-self: stretch; */
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    /* color: var(--primary-d71513); */
    text-align: left;
    /* display: none; */
    color: var(--primary-d71513);
    
  }
  
  
  
  .hint-text3 {
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    color: var(--grey-600);
    text-align: left;
    display: none;
  }
  .input-field2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .form1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .icon17 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text17 {
    position: relative;
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    font-weight: 600;
    font-family: var(--text-sm-semibold);
    color: var(--base-white);
    text-align: left;
  }
  .buttons6 {
    align-self: stretch;
  }
  .actions2 {
    border: none;
    font-family: Poppins, sans-serif;
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .remember-password2 {
    color: #262626;
  }
  .span {
    color: var(--primary-d71513);
    background: transparent;
    border: none;
    font-family: Poppins;
  }
  .remember-password1 {
    font-family: var(--text-sm-semibold);
  }
  .sign-in1 {
    font-weight: 600;
    font-family: var(--text-sm-semibold);
  }
  .remember-password-sign-container {
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    text-align: center;
  }
  .content6 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .forgot-password .content5 {
    width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-13xl);
    background-color: #ffffff;
    padding: 2rem;
  }
  .forgot-password {
    position: relative;
    background-image: url("../../../../images/popUpBackground.svg");
    width: 100%;
    height: 960px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-77xl) var(--padding-13xl) var(--padding-29xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
  }
  
  @media screen and (max-width: 420px) {
    .forgot-password .content5 {
      width: 300px;
    }
  }
  
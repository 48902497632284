
  .label {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  .search-md-icon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text4 {
    position: relative;
    line-height: 24px;
  }
  .content2 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .input {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--grey-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-500);
  }
  .input-with-label {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .hint-text {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--grey-600);
    display: none;
  }
  .input-dropdown {
    align-self: stretch;
    width: 574px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .content1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-45xl);
  }
  .nav-item-button {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .heart-icon {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .nav-item-button1 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    width: 40px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .notifications-icon {
    position: relative;
    width: 21.5px;
    height: 21.5px;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
  }
  .avatar-icon {
    position: relative;
    border-radius: var(--br-181xl);
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .dropdown {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .container {
    width: 1280px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base) 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
  }
  .divider {
    align-self: stretch;
    position: relative;
    background-color: var(--grey-200);
    height: 1px;
  }
  .dot-icon1 {
    position: relative;
    width: 10px;
    height: 10px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text5 {
    position: relative;
    font-weight: 500;
  }
  .content4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .text6 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  .badge1 {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--grey-700);
  }
  .chevron-down-icon1 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .nav-item-base {
    border-radius: var(--br-7xs);
    background-color: var(--grey-50);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .bar-chart-01-icon1 {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .badge2 {
    border-radius: var(--br-base);
    background-color: var(--grey-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
  }
  .nav-item-base1 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .nav-item-base-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .p-coin-icon {
    position: relative;
    width: 22px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .nav-item-base5 {
    border-radius: var(--br-7xs);
    background-color: var(--m3-sys-light-on-primary);
    border: 1px solid var(--gray-500);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .navigation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
    color: var(--grey-700);
  }
  .container1 {
    width: 1280px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base) 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-101xl);
    font-size: var(--text-md-semibold-size);
    color: var(--grey-900);
  }
  .header-navigation {
    align-self: stretch;
    background-color: var(--m3-sys-light-on-primary);
    border-bottom: 1px solid var(--grey-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-parentAddress {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-13xl);
    padding-top: 20px;
  }
  .address-inner {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-xs) var(--padding-61xl) var(--padding-13xl);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .address {
    position: relative;
    background-color: var(--m3-sys-light-on-primary);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--m3-label-large-size);
    color: var(--grey-700);
    font-family: var(--text-md-semibold);
  }
  .address_error{
    color: #D71513;
  }

  .address__remove__modal{
    h3{
      font-size: 20px;
      margin-bottom: 65px;
      margin-top: 130px;
    }
  }

  .addressAdressMain {
    overflow: hidden;
    position: relative;
    background-color: var(--m3-sys-light-on-primary);
    width: 100vw;
    align-items: flex-start;
    font-size: var(--m3-label-large-size);
    color: var(--grey-700);
  }


  @media screen and (max-width: 960px) {
    .frame-parentAddress {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    
  }
  
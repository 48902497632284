.apply-couponBillVoucherForm {
    align-self: stretch;
    position: relative;
  
  }
  .path-3991-strokeBillVoucherForm {
    position: relative;
    width: 15.98px;
    height: 16.74px;
  }
  .path-3991-stroke-wrapperBillVoucherForm {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-parent19BillVoucherForm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-11xs);
    width: 100%;
  }
  .aroow-icon1BillVoucherForm BillVoucherForm{
    position: relative;
    width: 6.97px;
    height: 12.43px;
  }
  .aroow-iconBillVoucherForm {
    width: 24px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs) 0px var(--padding-base);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    transform-origin: 0 0;
  }
  .frame-parent18BillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: var(--text-md-semibold-size);
    color: var(--gray-800);
  }
  .disabled__form{
    cursor: not-allowed !important;
  }
  .apply-coupon-parentBillVoucherForm {
    align-self: stretch;
    background-color: var(--color-aliceblue);
    display: flex;
    flex-direction: column;
    padding: var(--padding-base);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    color: var(--color-seagreen);
    cursor: pointer;
    &.applyed-coupon-block{
      cursor: default;
    }
    .cross-icon1BillVoucherForm{
      height: 10px;
      cursor: pointer;
    }
    .voucher__discount__amount{
      color: #168952;
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .mask-group-iconBillVoucherForm {
    position: relative;
    width: 363px;
    height: 86px;
  }
  .p-coin-icon3BillVoucherForm {
    position: relative;
    width: 49px;
    height: 49px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .div2BillVoucherForm {
    position: relative;
    font-weight: 600;
  }
  .available-p-coinsBillVoucherForm {
    position: relative;
    font-size: var(--m3-label-large-size);
    color: var(--color-gray-100);
  }
  .parent1BillVoucherForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--Primary---D71513, #D71513);
  }
  .p-coin-parentBillVoucherForm {
    width: 190.44px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .mask-group-parentBillVoucherForm {
    width: 355px;
    height: 78px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    border-radius: 8px;
    border-radius: 10px;
    border: 1px solid var(--Primary---D71513, #D71513);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background: url('../../../../images/cart/P-Coin-BG.png') no-repeat right;
  }
  .enter-no-ofBillVoucherForm {
    position: relative;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    width: 100%;
    img{
      transform: rotate(91deg);
    }
  }
  .p-coin-icon4BillVoucherForm {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .label-text5BillVoucherForm {
    position: relative;
  }
  .label-text4BillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    input{
      &:focus{
        outline: none;
      }
    }
  }
  .supporting-text2BillVoucherForm {
    flex: 1;
    position: relative;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
  .supporting-text1BillVoucherForm {
    align-self: stretch;
    display: none;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-base) 0px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--text-xs-medium-size);
    color: var(--m3-sys-light-on-surface-variant);
    font-family: var(--m3-label-large);
  }
  .text-fieldBillVoucherForm {
    flex: 1;
    border-radius: var(--br-8xs);
    border: 1px solid var(--grey-767676);
    box-sizing: border-box;
    height: 46px;
    display: flex;
    flex-direction: column;
    padding:0.5rem 0.3rem 0.5rem 0.5rem;
    align-items: flex-start;
    justify-content: center;
  }
  .redeem-wrapperBillVoucherForm {
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-xl);
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--m3-sys-light-on-primary);
  }
  .text-field-parentBillVoucherForm {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--grey-767676);
  }
  .enter-no-of-p-coins-to-redeem-parentBillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--m3-label-large-size);
    color: var(--base-black);
  }
  .frame-parent20BillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-lg);
  }
  .frame-wrapper3BillVoucherForm {
    align-self: stretch;
    background-color: var(--color-aliceblue);
    display: flex;
    flex-direction: column;
    padding: var(--padding-lg);
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--px-semibold-size);

  }
  .discount{
    &__applied__block{
      width: 100%;
    }
    &__title{
      display: flex;
      gap: 10px;
      align-items: center;
      h3{
        margin-bottom: 0;
        color: var(--base-black, #1C1C1C);
        /* 16px/Medium */
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      img{
        height: 24px;
        width: 24px;
      }
      &__block{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__remove{
      color: #DB1F1F;
      text-align: right;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      margin-bottom: 0;
      cursor: pointer;
    }
    &__body{
 
      &__content__block{
        display: flex;
        align-items: center;
        gap: 10px;
        img{
          display: flex;
          width: 20px;
          height: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
        h3{
          color: var(--Primary---D71513, #D71513);
          text-align: right;
          /* 16px/Medium */
          font-family: 'Poppins';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0;
        }
      }
      &__content__text--two{
        color: var(--848484, #848484);
        text-align: right;
        /* 16px/Medium */
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
        span{
          color: #168952;
        }
      }
      &__block{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 25px;
        margin-top: 15px;
      }
      &__amount__block{
        h3{
          color: #168952;
          /* 16px/Medium */
          font-family: 'Poppins';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0;
        }
      }
    }
  }
  .frame-iconBillVoucherForm {
    position: relative;
    width: 21px;
    height: 21px;
  }
  .frame-parent23BillVoucherForm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .chevron-up-iconBillVoucherForm {
    position: relative;
    border-radius: var(--br-981xl);
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frame-parent22BillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .label-text7BillVoucherForm {
    position: relative;
    color: var(--primary-d71513);
  }
  .label-text-parentBillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    input{
      &:focus{
        outline: none;
      }
    }
  }
  .text-field1BillVoucherForm {
    align-self: stretch;
    border-radius: var(--br-8xs);
    border: 1px solid var(--grey-767676);
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xs) var(--padding-xs);
    align-items: flex-start;
    justify-content: center;
    color: var(--grey-767676);
  }
  .frame-parent21BillVoucherForm {
    align-self: stretch;
    background-color: var(--color-aliceblue);
    display: flex;
    flex-direction: column;
    padding: var(--padding-base);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
  }
  .bill-details1BillVoucherForm {
    align-self: stretch;
    position: relative;
    font-weight: 500;
  }
  .sku-hrw-not-000160115BillVoucherForm {
    position: relative;
    color: var(--base-black);
    text-align: right;
  }
  .sku-hrw-not-000160117BillVoucherForm {
    position: relative;
    color: var(--color-seagreen);
    text-align: right;
  }
  .sku-hrw-not-000160121BillVoucherForm {
    position: relative;
    color: var(--primary-d71513);
    text-align: right;
  }
  .frame-child7BillVoucherForm {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--border-ededed);
    box-sizing: border-box;
    height: 1px;
  }
  .sku-hrw-not-000160127BillVoucherForm {
    position: relative;
    font-weight: 500;
    text-align: right;
    min-width: 150px;
  }
  .sku-hrw-not-0001601-parent11BillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    color: var(--base-black);
  }
  .frame-parent24BillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    font-size: var(--m3-label-large-size);
    color: var(--grey-767676);
  }
  .bill-details-groupBillVoucherForm {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
    font-size: var(--px-medium-size);
  }
  .state-layer4BillVoucherForm {
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    align-items: center;
    justify-content: center;
  }
  .button2BillVoucherForm {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  // .button-containerBillVoucherForm {
  //   align-self: stretch;
  //   display: flex;
  //   flex-direction: column;
  //   padding: var(--padding-3xs) 0px 0px;
  //   align-items: center;
  //   justify-content: flex-end;
  //   text-align: center;
  //   color: var(--m3-sys-light-on-primary);
  // }
  .cartBillVoucherForm__btn{
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    align-items: center;
    justify-content: center;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    color: #FFFFFF;
    width: 100%;

  }
  .cartBillVoucherForm__btn--disabled{
    cursor: not-allowed;
    background: #F9DFDF;
  }
  .frame-parent17BillVoucherForm {
    background-color: var(--m3-sys-light-on-primary);
    border-left: 1px solid var(--border-ededed);
    box-sizing: border-box;
    width: 439px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
    text-align: left;
    font-size: var(--m3-label-large-size);
    color: var(--base-black);
    font-family: var(--text-md-semibold);
}
  .discountType__Text{
    margin-Bottom: 0.7rem;
  }
  .Input__Inner__Btn{
    color:#FFFFFF;
    font-weight: 500;
    background-color: #D71513;
    padding: 0.5rem;
    border-radius: 5px;
    margin-left: auto;
    cursor: pointer;
  }
  .slider__Container{
    display: flex;
    align-items: center;
    gap: 1rem;
    input[type='range'] {
        overflow: hidden;
        width: 200px;
        height: 5px;
        -webkit-appearance: none;
        background-color: rgb(187, 187, 187);
        cursor: pointer;
      }
    
      input[type='range']::-webkit-slider-runnable-track {
        -webkit-appearance: none;
      }
    
      input[type='range']::-webkit-slider-thumb {
        width: 20px;
        -webkit-appearance: none;
        height: 3px;
        cursor: ew-resize;
        background: #DB1F1F;
        box-shadow: -200px 0 0 200px red;
      }
    
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #DB1F1F;
                height: 3px;
      }
  }
  .decrement__Btn{
    color:#D71513; 
    border:2px solid #D71513;
    padding: 0.1rem 0.3rem;
    border-radius: 50%;
    cursor: pointer;
  }
    .increment__Btn {
      color: #D71513;
      border: 2px solid #D71513;
        padding: 0.1rem 0.3rem;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
    }
    .ToolTip{
      padding: 0.3rem;
      border-radius: 2px;
      font-size: 10px;
      font-family: "Poppins";
      position: absolute;
      top: -25px;
      color: #1C1C1C;
      background-color: #FFFFFF;
      width: 200px;
      font-weight: 400;
      border: 1px solid rgb(152, 152, 152);
    }

  @media screen and (max-width: 1100px) {
    .frame-parent17BillVoucherForm {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 480px) {
    .text-fieldBillVoucherForm {
      width: 57%;
      flex: auto;
    }
    .label-text5BillVoucherForm{
      width: 65%;
    }
    .slider__Container{
      input[type='range'] {
          overflow: hidden;
          width: 150px;
          height: 5px;
          -webkit-appearance: none;
          background-color: rgb(187, 187, 187);
          cursor: pointer;
        }
    }
  }
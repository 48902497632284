.supporting-textFooter {
    align-self: stretch;
    position: relative;
    line-height: 24px;
  }
  .logo-and-supporting-textFooter {
    max-width: 620px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .icon12Footer {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text31Footer {
    position: relative;
    line-height: 24px;
    font-weight: 600;
  }
  .buttons4FooterFooter {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .footer-linkFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .text34Footer {
    position: relative;
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    font-weight: 600;
    font-family: var(--text-md-semibold);
    color: var(--grey-200);
    text-align: left;
  }
  .footer-link3Footer {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .footer-linksFooter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    flex-wrap: wrap;
    padding-block: 25px;
    cursor: pointer;
  }
  .logo-and-linksFooter {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
  .headingFooter {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  .actions1Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .app-store-actionsFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    color: var(--base-white);
  }
  .content9Footer {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .container2Footer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0px var(--padding-13xl);
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--grey-200);
    font-family: var(--text-md-semibold);
  }
  
  @media screen and (max-width: 420px) {
    .footer-linksFooter {
      flex-direction: row;
    }
  
    .logo-and-linksFooter {
      flex: unset;
      align-self: stretch;
    }
  }


  @media screen and (max-width: 700px) {
    .content9Footer {
      flex-direction: column;
    }
    .actions1Footer{
      flex-direction: row;
    }
    .footer-linksFooter{
      padding-inline: 0;
    }
  }
  @media screen and (max-width: 530px) {
    .footer-linksFooter{
      justify-content: flex-start;
      gap: 25px;
    }
  }
  @media screen and (max-width: 900px) {
    .container2Footer {
     padding: 0px;
    }
  }

  @media screen and (max-width: 360px) {
    .actions1Footer{
     gap: 10px;
    }
  }

  
  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.slick-arrow {
  background: transparent !important;
}
.slick-prev,
.slick-next {
  width: 29px;
  height: 29px;
}
.slick-prev:before {
  color: #e1e1e1;
  font-size: 30px;
}
.slick-next:before {
  color: #e1e1e1;
  font-size: 30px;
}
// Modal Style
.big__modal {
  box-shadow: 1px 1px 10px 0px rgb(87 87 87 / 19%);
}
.modal-dialog {
  .modal-title {
    font-size: 25px;
  }
  button.close {
    font-size: 38px;
    color: var(--base-black, #1c1c1c);
    margin-right: -8px;
  }
}
.big__modal {
  width: 30%;
}
body.modal-open {
  overflow: visible !important;
  padding-right: 0px !important;
}
.modal-backdrop.light__modal__background {
  background-color: #fff;
}
.left__modal {
  transform: translateX(-100%);
  transition: transform 0.7s ease-in-out;
}
.left__modal.show {
  transform: translateX(0);
  transition: transform 0.7s ease-in-out;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
// .slick-arrow {
//   opacity: 0;
//   transition: all 0.3s ease-in;
// }

.slick-arrow {
  @media only screen and (min-width: 1200px) {
    opacity: 0;
  }
}
.slick-slider {
  &:hover {
    @media only screen and (min-width: 1200px) {
      .slick-arrow {
        opacity: 1;
      }
    }
  }
}
// .slick-arrow {
//   opacity: 1;
// }
.slick-prev:before,
.slick-next:before {
  color: #d71513;
}
@media screen and (max-width: 1360px) {
  .big__modal {
    width: 45%;
  }
}

@media screen and (max-width: 980px) {
  .big__modal {
    width: 60%;
  }
}

@media screen and (max-width: 680px) {
  .big__modal {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .big__modal {
    width: 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

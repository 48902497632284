.faq-banner-block{
    display: flex;
    padding: 20px 120px;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    background: var(--Gray-100, #F5F5F5);
    h2{
        color: var(--Primary---D71513, #D71513);
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    p{
        color: var(--Base-Black, #1C1C1C);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 556px;
    }
}

.faq-content-block{
    display: flex;
    padding: 60px 120px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    .accordion{
        width: 100%;
    }
    .accordion-button{
        color: var(--Gray-800, #262626);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .accordion-button:not(.collapsed){
        background-color: #fff;
        box-shadow: none;
    }
    .accordion-button:focus{
        border-color: transparent;
        box-shadow: none;
    }
    .accordion-flush .accordion-item{
        padding: 32px 0;
    }
    .accordion-button::after{
        background-image: url("../../../../images//FAQ/plus-circle.png")
    }
    .accordion-button:not(.collapsed)::after{
        background-image: url("../../../../images//FAQ/minus-circle.png")
    }
}


@media screen and (max-width: 1180px) {
    .faq-banner-block{
        padding: 20px 45px;
    }
    .faq-content-block{
        padding: 60px 45px;
    }
}

@media screen and (max-width: 880px) {
    .faq-banner-block{
        flex-direction: column;
        gap: 45px;
    }
    .faq-banner-img-block{
        img{
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 580px) {
    .faq-content-block{
        padding: 40px 30px;
    }
}
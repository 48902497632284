.SubCategoryDropdown__Container{
    padding:1rem;
    width:800px;
    background-color: rgb(249, 246, 246);
    color:black;
    position: absolute;
    z-index:4;
    top:-3px;
    left:100%;
    gap:2rem;
    display: flex;
    flex-wrap: wrap;
        align-items: start;
        border-radius: 5px;
    .SubCategoryDropdown__Container__Inner{
        margin:0.5rem;
        text-align:left;
        width:200px;
    }
    .SubCategoryDropdown__Container__Inner__Text{
        color:#767676;
        font-family:"Poppins" ;
        font-size: 14px;
        margin: 1rem 0;
        &:hover{
            color:#D71513
        }
    }
        
    .SubCategoryDropdown__Container__Inner__Icon{
        width:30px;
        &:hover {
                color: #D71513
            }
    }
    .SubCategoryDropdown__Container__Inner__Head{
        font-family: "Poppins";
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap:0.5rem;
        &:hover {
                color: #D71513
            }
    }
}

.sign-in{
  .error__text{
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }
}
.logo-1-icon {
    position: relative;
    width: 80%;
    margin: 0 auto;
    cursor: pointer;
    /* height: 48px; */
    /* padding-inline: 30px; */
    /* margin-block: 20px; */
    object-fit: cover;
    /* margin-inline: auto; */
  }

  .pass-div {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .eye-icon-1 {
    margin-left: -30px;
    padding-right: 10px;
  }
  

  .text3 {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  .headerSignIn {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .label1 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--grey-700);
    text-align: left;
    margin-bottom: 10px;
  }

  
  
  .input1 {
    border: 1px solid var(--grey-300);
    font-family: var(--text-md-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    align-self: stretch;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    outline: none;
  }
  
  .input1:hover {
    border: 1px solid var(--primary-300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dce8ff;
  
  }
  .input__Head{
    color:black;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 400;

  }
  
  .hintpass {
    /* align-self: stretch; */
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    /* color: var(--primary-d71513); */
    text-align: left;
    /* display: none; */
    color: var(--primary-d71513);
 }



  .input-field {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .input2 {
    border: 1px solid var(--grey-300);
    outline: none;
    font-family: var(--text-md-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }
  
  .input2:hover {
    border: 1px solid var(--primary-300);
    box-shadow: none;
   // box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dce8ff;
  
  }
  
  
  .formSignIn {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .icon4 { 
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text4 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: left;
  }
  .buttons {
    flex: 1;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    margin-inline: auto;
    justify-items: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: var(--gap-5xs);
  }
  
  .rowSignIn {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: white;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }
  .icon6 {
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 20px;
    display: none;
  }
  .text5 {
    flex: 1;
    position: relative;
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    font-weight: 600;
    font-family: var(--text-md-semibold);
    color: var(--base-white);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
  .buttons1SignIn {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .actionsSignIn {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &.disabled{
      cursor: not-allowed;
    }
  }
  
  .actions:hover {
    box-shadow: 4px 4px 10px rgba(13, 14, 15, 0.277);
    border-radius: 10px;
  
  }
  
  .content1SignIn {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .dont-have-an {
    position: relative;
    line-height: 20px;
  }
  .text6SignIn {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: left;
    display: inline-block;
  }
  .buttons2SignIn {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .row1SignIn {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--grey-600);
    margin-bottom: 1rem;
  }
  .contentSignIn {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-13xl);
  }
  
  .sign-in {
    position: relative;
    background-color: var(--base-white);
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: var(--display-sm-semibold-size);
    color: var(--color-gray-100);
    font-family: var(--text-md-semibold);
    border-radius: 10px;
  }
  
  
  
  
  @media screen and (max-width: 420px) {
    .row1 {
      flex-direction: column;
      gap: var(--gap-9xs);
      align-items: center;
      justify-content: center;
    }
    .contentSignIn{
      width: 300px;
    }
    .sign-in {
      padding: var(--padding-13xl);
    }
  }
  
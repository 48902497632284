
  .single-itemSingleCatalogueItem {
    position: relative;
    background-color: var(--base-white);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    color: var(--grey-700);
    font-family: var(--text-md-regular);
    overflow: hidden;
  }


  .singleitem-parentSingleCatalogueItem {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
    padding-inline: 80px;
  }
  

  @media screen and (max-width: 600px) {
    .singleitem-parentSingleCatalogueItem  {
        padding-inline: 0px;
    }
  }

.Bottom__Cards__Container{
     display: flex;
     align-items: center;
     flex-wrap: wrap;
     gap: 1rem;
     margin-top: 1.5rem;
    .Bottom__Card{
        cursor: pointer;
        background-color: #ffffff;
        padding: 2rem;
        text-align: center;
        width:23%;
        img{
            margin-bottom: 0.5rem;
        }
    }
    .BottomCard__GreenColor{
        cursor: pointer;
        background-color: #36BE49;
        color:#ffffff;
        padding: 2rem;
        text-align: center;
                width: 23%;
        img{
                    margin-bottom: 0.5rem;
        }
    }
}
@media screen and (max-width:788px) {
    .Bottom__Cards__Container {
        display: flex !important;
    }
    .Bottom__Card{
        width:48% !important;
    }
        .BottomCard__GreenColor {
            width: 48% !important;
        }

}
@media screen and (max-width:458px) {
    

    .Bottom__Card {
        width: 100% !important;
    }

    .BottomCard__GreenColor {
        width: 100% !important;
    }

}
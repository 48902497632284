.sku-hrw-not-00016011SingleCatalogueItemDetails {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 600;
    font-family: inherit;
  }
  .sku-hrw-not-0001601-wrapperSingleCatalogueItemDetails {
    border-bottom: 2px solid var(--primary-d71513);
    box-sizing: border-box;
    width: 155px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sku-hrw-not-00016012SingleCatalogueItemDetails {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 500;
    font-family: inherit;
  }
  .sku-hrw-not-0001601-containerSingleCatalogueItemDetails {
    width: 155px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--grey-767676);
  }
  .frame-parent6SingleCatalogueItemDetails {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .share-2-childSingleCatalogueItemDetails {
    position: absolute;
    height: 92.42%;
    width: 84.09%;
    top: 3.79%;
    right: 7.95%;
    bottom: 3.79%;
    left: 7.95%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .share-2SingleCatalogueItemDetails {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 22px;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .sku-hrw-not-00016013SingleCatalogueItemDetails {
    position: relative;
    font-weight: 500;
  }
  .p-coin-icon2SingleCatalogueItemDetails {
    position: relative;
    width: 14px;
    height: 15px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .sku-hrw-not-0001601-parentSingleCatalogueItemDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-10xs);
  }
  .share-2-parentSingleCatalogueItemDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-wrapper5SingleCatalogueItemDetails {
    border-radius: var(--br-9xs);
    background-color: var(--base-white);
    border: 1px solid var(--grey-767676);
    box-sizing: border-box;
    width: 180px;
    height: 41px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frame-wrapper4SingleCatalogueItemDetails {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--text-md-regular-size);
    color: var(--grey-767676);
  }
  .frame-parent5SingleCatalogueItemDetails {
    border-bottom: 1px solid var(--color-lightgray-100);
    box-sizing: border-box;
    width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .improve-business-efficiencySingleCatalogueItemDetails {
    margin: 0;
    position: relative;
    font-size: var(--px-medium1-size);
    font-weight: 500;
    font-family: inherit;
    color: var(--base-black);
    display: inline-block;
    width: 1280px;
    padding-top: 22px;
  }
  .designed-to-enhanceSingleCatalogueItemDetails {
    margin-block-start: 0;
    margin-block-end: 10px;
  }
  .the-ecotank-l32561SingleCatalogueItemDetails {
    margin: 0;
  }
  .designed-to-enhance-containerSingleCatalogueItemDetails {
    position: relative;
    font-size: var(--text-md-regular-size);
    color: var(--grey-767676);
    display: inline-block;
    width: 1280px;
  }
  .frame-sectionSingleCatalogueItemDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xl);
    text-align: left;
    color: var(--primary-d71513);
    font-family: var(--text-sm-medium);
  }
  
.page__layout {
  &__main {
    padding: 20px 80px 0px;
  }
  .product__page__breadcrumb {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--grey-600, #475467);
    /* Text sm/Medium */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    a {
      color: #667085;
    }
    .breadcrumb__divider {
      color: #d0d5dd;
      margin-bottom: -2px;
      transform: rotate(11deg);
    }
    svg {
      font-size: 22px;
      margin-top: -5px;
    }
    .nested__page__block {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .nested__page__name {
      color: var(--Primary---D71513, #d71513);
      text-transform: capitalize;
    }
  }
}

@media screen and (max-width: 1080px) {
  .page__layout {
    &__main {
      padding: 30px;
    }
  }
}
@media screen and (max-width: 480px) {
  .page__layout {
    &__main {
      padding: 10px;
    }
  }
}

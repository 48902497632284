.SplashPop__Container{
  // .modal-content{
  //   background-color: transparent !important;
  // }
  //   img{
  //       width:50%;
  //       height: 50%;
  //   }
  .Cross_Icon{
    position: absolute !important;
  }
  @media screen and (max-width:800px){
    .Cross_Icon{ width:10px}
  }
  
}
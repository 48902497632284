@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



body {
  margin: 0;
  line-height: normal;
}
:root {
   /* fonts */
   --text-md-semibold: Poppins;

   /* font sizes */
   --text-md-semibold-size: 16px;
   --text-sm-semibold-size: 14px;
   --text-xs-medium-size: 12px;
   --font-size-13xl: 32px;
   --px-regular-size: 10px;
   --px-medium-size: 20px;
 
   /* Colors */
   --grey-600: #475467;
   --grey-700: #344054;
   --grey-500: #667085;
   --grey-50: #f9fafb;
   --grey-100: #f2f4f7;
   --grey-200: #eaecf0;
   --base-white: #fff;
   --primary-600: #155eef;
   --grey-300: #d0d5dd;
   --grey-767676: #888686;
   --grey-800: #1d2939;
   --base-black: #1c1c1c;
   --grey-900: #101828;
   --color-orangered: #eb5806;
   --primary-d71513: #d71513;
   --color-oldlace: #fef4df;
   --color-ghostwhite: #f7fafe;
   --gray-500: #737373;
 
   /* Gaps */
   --gap-5xs: 8px;
   --gap-xs: 12px;
   --gap-45xl: 64px;
   --gap-13xl: 32px;
   --gap-base: 16px;
   --gap-5xl: 24px;
   --gap-3xs: 10px;
   --gap-9xs: 4px;
   --gap-10xs: 3px;
   --gap-7xs: 6px;
   --gap-mini: 15px;
   --gap-12xs: 1px;
   --gap-8xs: 5px;
   --gap-sm: 14px;
 
   /* Paddings */
   --padding-5xs: 8px;
   --padding-xs: 12px;
   --padding-11xs: 2px;
   --padding-3xs: 10px;
   --padding-lg: 18px;
   --padding-45xl: 64px;
   --padding-29xl: 48px;
   --padding-13xl: 32px;
   --padding-101xl: 120px;
   --padding-mini: 15px;
   --padding-2xl: 21px;
   --padding-141xl: 160px;
   --padding-xl: 20px;
   --padding-base: 16px;
 
   /* border radiuses */
   --br-8xs: 5px;
   --br-6xs: 7px;
   --br-5xs: 8px;
   --br-7xs: 6px;
   --br-base: 16px;
   --br-981xl: 1000px;
   --br-12xs: 1px;
 
   /* Effects */
   --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
 
  /* fonts */
  --text-md-semibold: Poppins;

  /* font sizes */
  --text-md-semibold-size: 16px;
  --text-sm-semibold-size: 14px;
  --display-sm-semibold-size: 30px;
  --px-medium-size: 12px;
  --font-size-13xl: 32px;
  --px-regular-size: 10px;
  --px-medium1-size: 20px;
  --display-lg-medium-size: 48px;
  --px-semibold-size: 24px;

  /* Colors */
  --primary-600: #155eef;
  --base-white: #fff;
  --primary-700: #004eeb;
  --grey-600: #475467;
  --grey-700: #344054;
  --grey-300: #d0d5dd;
  --grey-500: #667085;
  --primary-d71513: #d71513;
  --primary-300: #84adff;
  --grey-767676: #888686;
  --grey-800: #1d2939;
  --base-black: #1c1c1c;
  --color-gray-100: #101828;
  --grey-50: #f9fafb;
  --grey-100: #f2f4f7;
  --grey-200: #eaecf0;
  --color-orangered: #eb5806;
  --color-oldlace: #fef4df;
  --color-ghostwhite: #f7fafe;
  --gray-500: #737373;
  --primary-100: #dce8ff;
  --primary-800: #16A34A;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-7xs: 6px;
  --gap-13xl: 32px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-xl: 20px;
  --gap-base: 16px;
  --gap-3xs: 10px;
  --gap-10xs: 3px;
  --gap-mini: 15px;
  --gap-8xs: 5px;
  --gap-12xs: 1px;
  --gap-sm: 14px;
  --gap-xs: 12px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-lg: 18px;
  --padding-sm: 14px;
  --padding-77xl: 96px;
  --padding-13xl: 32px;
  --padding-29xl: 48px;
  --padding-45xl: 64px;
  --padding-101xl: 120px;
  --padding-mini: 15px;
  --padding-5xs: 8px;
  --padding-xs: 12px;
  --padding-2xl: 21px;
  --padding-141xl: 160px;
  --padding-xl: 20px;
  --padding-base: 16px;
  --padding-11xs: 2px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-8xs: 5px;
  --br-6xs: 7px;
  --br-7xs: 6px;
  --br-981xl: 1000px;
  --br-12xs: 1px;
  --br-base: 16px;
  --br-9xl: 28px;
  --br-xs: 12px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-base: 4px 4px 10px rgba(13, 14, 15, 0.277);

  --text-md-semibold: Poppins;
  --m3-label-large: Roboto;

  /* font sizes */
  --text-md-semibold-size: 16px;
  --m3-label-large-size: 14px;
  --text-xs-medium-size: 12px;
  --px-medium-size: 18px;
  --px-medium1-size: 24px;
  --px-semibold-size: 20px;

  /* Colors */
  --grey-600: #475467;
  --m3-sys-light-on-surface-variant: #49454f;
  --grey-700: #344054;
  --color-darkslategray-100: #3c3c3c;
  --color-darkslategray-200: #3a3a3a;
  --m3-sys-light-primary: #6750a4;
  --m3-sys-light-on-primary: #fff;
  --grey-50: #f9fafb;
  --color-whitesmoke-100: #f4f4f4;
  --grey-100: #f2f4f7;
  --border-ededed: #ededed;
  --grey-200: #eaecf0;
  --grey-767676: #888686;
  --color-gray-100: #797979;
  --m3-sys-light-outline: #79747e;
  --grey-800: #1d2939;
  --gray-800: #262626;
  --base-black: #1c1c1c;
  --grey-900: #101828;
  --grey-300: #d0d5dd;
  --primary-d71513: #d71513;
  --color-seagreen: #168952;
  --color-teal: #007161;
  --color-dimgray-100: #747474;
  --gray-500: #737373;
  --grey-500: #667085;
  --color-aliceblue: #f6fbff;
  --color-crimson: #db2f2f;
  --color-orangered: #eb5806;
  --color-gainsboro: #d9d9d9;
  --color-mistyrose: #f9dfdf;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-45xl: 64px;
  --gap-13xl: 32px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-xl: 20px;
  --gap-sm: 14px;
  --gap-3xs: 10px;
  --gap-lg: 18px;
  --gap-mini: 15px;
  --gap-8xs: 5px;
  --gap-10xs: 3px;
  --gap-101xl: 120px;
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-11xs: 2px;
  --gap-2xs: 11px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-5xl: 24px;
  --padding-11xs: 2px;
  --padding-5xs: 8px;
  --padding-xs: 12px;
  --padding-45xl: 64px;
  --padding-29xl: 48px;
  --padding-13xl: 32px;
  --padding-61xl: 80px;
  --padding-mini: 15px;
  --padding-sm: 14px;
  --padding-8xs: 5px;
  --padding-base: 16px;
  --padding-9xs: 4px;
  --padding-lg: 18px;
  --padding-xl: 20px;

  /* border radiuses */
  --br-8xs: 5px;
  --br-6xs: 7px;
  --br-5xs: 8px;
  --br-81xl: 100px;
  --br-base: 16px;
  --br-7xs: 6px;
  --br-981xl: 1000px;
  --br-181xl: 200px;
  --br-12xs: 1px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
// img{
//   z-index:-10
// }

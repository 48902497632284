
.overview {
    position: relative;
  }
  .overview-wrapper {
    align-self: stretch;
    border-bottom: 1px solid var(--color-gainsboro);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-3xs) var(--padding-9xl) 0px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--px-medium-size);
    padding-bottom: 1.5rem !important;
    width:80%;
  }
  .profile1 {
    position: relative;
    font-weight: 500;
  }
  .chevron-right-icon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .profile-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 0px var(--padding-3xs) 0px 0px;
    align-items: flex-start;
    justify-content: space-between;
    color: var(--primary-d71513);
  }
  .orders {
    position: relative;
    font-size: 16px;
    font-family: "Poppins";
    color: var(--color-dimgray-100);
    text-align: left;
    padding: 0.2rem 0;
  }
  .orders-wrapper {
    cursor: pointer;
    border: none;
    padding: var(--padding-3xs) 0px var(--padding-9xl);
    background-color: transparent;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gainsboro);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .address-book-parent {
    align-self: stretch;
    border-bottom: 1px solid var(--color-gainsboro);
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xs) 0px var(--padding-9xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  .log-out-03-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) 0px var(--padding-mini);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
    color: var(--color-red-100);
    background: transparent;
    border: none;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    color:#D71513;
  }
  .frame-parent1 {
    align-self: stretch;
    border-right: 1px solid var(--color-gainsboro);
    box-sizing: border-box;
    width: 35%;
    align-items: flex-start;
    display:flex;
    flex-direction: column !important;
    gap: 25px;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-dimgray-100);
    font-family: var(--font-work-sans);
   button{
    padding:0rem;
   }
  }
  .Bottom__Container{
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    width:80%;
  }
  .profile-wrapper{
    margin-top: 1rem;
    cursor: pointer;
    width: 80%;
  }
  .order-wrapper{
        cursor: pointer;
          border: none;
          padding: var(--padding-3xs) 0px var(--padding-9xl);
          background-color: transparent;
          align-self: stretch;
          border-bottom: 1px solid var(--color-gainsboro);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
                  padding: 1rem 0 !important ;
                  width:80%;
  }
  .DailyLogin-wrapper{
    cursor: pointer;
      border: none;
      padding: var(--padding-3xs) 0px var(--padding-9xl);
      background-color: transparent;
      align-self: stretch;
      border-bottom: 1px solid var(--color-gainsboro);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
          padding-bottom: 1.5rem !important;
          width:80%;
  }
  .ChangePass-wrapper{
    padding-bottom:1rem;
  }
  .e-invoice-wrapper {
    cursor: pointer;
  }
  .PCoin-wrapper{
    cursor: pointer;
      border: none;
      background-color: transparent;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 80%;
  }

  .activeTab{
    color:#D71513;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 17px;
    
    &::after{
      content:url("../../../../images/account/Menu/chevronRight.svg");
       display: block;      
    }
  }

    @media screen and (max-width: 970px) {
      .frame-parent1 {
        display: none !important;
      }
    }
.logo-1-icon3 {
    position: relative;
    width: 208px;
    height: 48px;
    object-fit: cover;
  }
  .text18 {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  .header3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .label4 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-sm-semibold);
    color: var(--grey-700);
    text-align: left;
  }
  
  /* .prefix-div {
    display: flex;
    gap: 0px;
  }
  
  .prefix-input {
    align-self: stretch;
  } */
  
  .prefix-phone {
    width: 50px;
    border: 1px solid var(--grey-300) ;
    border-right: none;
    font-family: var(--text-sm-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    /* align-self: stretch; */
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding-right: 0px;
    padding-left: var(--padding-3xs) ;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
    /* flex: 1; */
  
  }
  
  .phone-div {
    display: flex;
    gap: 0px;
    width: 100%;
  }
    
  .pass-div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  
  .input9 {
    border: 1px solid var(--grey-300);
    font-family: var(--text-sm-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    outline: none;
    flex: 1;
  }
  
  .input9.phone {
   border-left: none;
   border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
  
  }
  
  .input9:hover {
    border: 1px solid var(--primary-300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dce8ff;
  
  }
  
  .input9.phone:hover {
    border-left: none;
    box-shadow: 0px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 0px #dce8ff;
  }
  
  .password-lower-upper-red,   
  .password-length-red,
  .password-special-character-red,
  .password-number-red {
    display: flex;
    gap: 4px;
    color: var(--primary-d71513);
    margin-bottom: 5px;
  }
  
  .password-lower-upper-green,
  .password-length-green,
  .password-special-character-green,
  .password-number-green {
    display: flex;
    gap: 4px;
    color: #16A34A;
    margin-bottom: 5px;
  }
  
  
  
  
  .confirm-password-check-red {
    color: var(--primary-d71513);
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    text-align: left;
  }
  
  
  .confirm-password-check-green {
    color: #16A34A;
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    text-align: left;
  }
  
  
  .hint-text4 {
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    /* color: var(--primary-d71513); */
    text-align: left;
    /* display: none; */
    
  }
  
  .hint-text4-email {
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-sm-semibold);
    /* color: var(--primary-d71513); */
    text-align: left;
    /* display: none; */
    color: var(--primary-d71513);
  }
  
  
  .input-field3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .form2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .icon19 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .text19 {
    position: relative;
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    font-weight: 600;
    font-family: var(--text-sm-semibold);
    color: var(--base-white);
    text-align: left;
  }
  .buttons7 {
    cursor: pointer;
    border: none;
    padding: var(--padding-3xs) var(--padding-lg);
    background-color: var(--primary-d71513);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .actions3 {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .content8 {
    align-self: stretch;
    border-radius: var(--br-xs);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .text20 {
    position: relative;
    line-height: 20px;
  }
  .text21 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--text-sm-semibold);
    color: var(--primary-d71513);
    text-align: left;
  }
  .buttons8 {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    align-self: stretch;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .row2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-9xs);
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--grey-600);
  }
 
  .sign-up {
    position: relative;
    background-image: url("../../../../images/popUpBackground.svg") !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: var(--display-sm-semibold-size);
    color: var(--grey-900);
    font-family: var(--text-sm-semibold);
    border-radius: 10px;
    padding-bottom:3rem;
    .content7 {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: var(--gap-13xl);
      background-color: #ffffff;
      padding: 2rem;
      margin:2rem 0;
    }
  }
  
  
  .enter_valid_email {
    color: var(--primary-d71513);
  }
.cross___icon{
width: 40px;
  margin-left: auto;
  padding: 0.5rem;
  cursor:pointer;
}
// .disabled{
  
 
//     border: none;
//     padding: var(--padding-3xs) var(--padding-lg);
//     background-color: var(--primary-d71513);
//     align-self: stretch;
//     border-radius: var(--br-5xs);
//     box-shadow: var(--shadow-xs);
//     overflow: hidden;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     gap: var(--gap-5xs);
//     background-color: gray;
//       color: white;
//       pointer-events: none;
// }

    
  @media screen and (max-width: 420px) {
    .sign-up .content7 {
      width: 300px;
    }
  }
  
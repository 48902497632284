.canon-e3370-rd-printer-photo2-icon2CatalogueItem1 {
    position: relative;
    border-radius: var(--br-8xs) var(--br-8xs) 0px 0px;
    width: 100%;
    height: 210px;
    object-fit: cover;
  }
  .canon-pixma-e3370r2CatalogueItem1 {
    align-self: stretch;
    position: relative;
    font-weight: 500;
  }
  .rm399001CatalogueItem1 {
    position: relative;
  }
  .rm345001CatalogueItem1 {
    position: relative;
    font-weight: 500;
    color: var(--base-black);
  }
  .off1CatalogueItem1 {
    position: relative;
    color: var(--color-orangered-100);
  }
  .rm39900-groupCatalogueItem1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .p-coin-icon5CatalogueItem1 {
    position: relative;
    width: 16px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .you-can-earn-containerCatalogueItem1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-10xs);
  }
  .frame-parent8CatalogueItem1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--text-xs-medium-size);
    color: var(--grey-767676);
  }
  .canon-pixma-e3370r-aio-wifi-in-containerCatalogueItem1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-wrapper2CatalogueItem1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xs) var(--padding-mini) var(--padding-mini);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .canon-e3370-rd-printer-photo2-containerCatalogueItem1 {
    position: relative;
    border-radius: var(--br-8xs);
    background-color: var(--base-white);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    width: 221px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-sm-medium-size);
    color: var(--base-black);
    font-family: var(--text-md-semibold);
  }
  


  .productHoveredDivCatalogueItem1 {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #D71513;
    border-radius: 5px;

  }


  .addToCartBtnCatalogueItem1 {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 32px;
  }


 
  @media screen and (max-width: 420px) {
    .canon-e3370-rd-printer-photo2-containerCatalogueItem1  {
      width: 175px;
    }
  }
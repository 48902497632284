.canon-e3370-rd-printer-photo2-iconSingleCatalogueItemCard {
    position: relative;
    border-radius: var(--br-8xs);
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
  .canon-e3370-rd-printer-photo2-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
  }
  .canon-e3370-rd-printer-photo2-icon6SingleCatalogueItemCard {
    position: relative;
    border-radius: var(--br-8xs);
    width: 550px;
    height: 550px;
    object-fit: cover;
  }
  .epson-ecotank-l3256SingleCatalogueItemCard {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: 30px;
    font-weight: 600;
    font-family: inherit;
  }
  .vector-iconSingleCatalogueItemCard {
    position: relative;
    width: 22px;
    height: 22px;
  }
  .starSingleCatalogueItemCard {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
  }
  .ratingsSingleCatalogueItemCard {
    position: relative;
  }
  .star-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    font-size: var(--px-medium1-size);
    color: var(--color-seagreen);
  }
  .divSingleCatalogueItemCard {
    position: relative;
    text-decoration: line-through;
  }
  .rm232900SingleCatalogueItemCard {
    position: relative;
    font-weight: 500;
    color: var(--base-black);
  }
  .offSingleCatalogueItemCard {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 400;
    font-family: inherit;
    color: var(--color-orangered-100);
  }
  .parentSingleCatalogueItemCard {
    width: 592px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .p-coin-icon1SingleCatalogueItemCard {
    position: relative;
    width: 24px;
    height: 27px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .p-coinsSingleCatalogueItemCard {
    position: relative;
    font-weight: 500;
  }
  .p-coin-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-10xs);
    color: var(--base-black);
  }
  .you-can-earn-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--px-medium3-size);
  }
  .frame-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--font-size-11xl);
    color: var(--grey-767676);
  }
  .frame-childSingleCatalogueItemCard {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-darkslategray-100);
    box-shadow: 0px 4px 6px rgba(205, 0, 0, 0.15);
    border: 2px solid var(--primary-d71513);
    box-sizing: border-box;
    width: 45px;
    height: 45px;
  }
  .ellipse-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    color: var(--primary-d71513);
  }
  .frame-itemSingleCatalogueItemCard {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-lightpink);
    width: 45px;
    height: 45px;
  }
  .ellipse-groupSingleCatalogueItemCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .frame-innerSingleCatalogueItemCard {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-seagreen);
    width: 45px;
    height: 45px;
  }
  .ellipse-divSingleCatalogueItemCard {
    position: relative;
    border-radius: 50%;
    background-color: var(--border-ededed);
    width: 45px;
    height: 45px;
  }
  .frame-groupSingleCatalogueItemCard {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
    text-align: center;
    font-size: var(--px-medium-size);
  }
  .color-parentSingleCatalogueItemCard {
    width: 592px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .chevron-up-iconSingleCatalogueItemCard {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .size-128-gb-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .frame-wrapperSingleCatalogueItemCard {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .gbSingleCatalogueItemCard {
    position: relative;
    font-size: var(--text-sm-medium-size);
    font-weight: 500;
    font-family: var(--text-sm-medium);
    color: var(--primary-d71513);
    text-align: center;
  }
  .gb-wrapperSingleCatalogueItemCard {
    align-self: stretch;
    border-bottom: 1px solid var(--border-ededed);
    display: flex;
    flex-direction: row;
    padding: 0px 0px var(--padding-7xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .rm-232900SingleCatalogueItemCard {
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-medium-size);
    font-family: var(--text-sm-medium);
    color: var(--base-black);
    text-align: left;
  }
  .in-stockSingleCatalogueItemCard {
    align-self: stretch;
    position: relative;
    font-size: var(--px-medium-size);
    font-family: var(--text-sm-medium);
    color: var(--color-teal);
    text-align: left;
  }
  .rm-232900-parentSingleCatalogueItemCard {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-buttonSingleCatalogueItemCard {
    cursor: pointer;
    border: 1px solid var(--primary-d71513);
    padding: var(--padding-5xs);
    background-color: transparent;
    border-radius: var(--br-8xs);
    box-sizing: border-box;
    width: 124px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .rm-332900SingleCatalogueItemCard {
    align-self: stretch;
    position: relative;
  }
  .in-stock1SingleCatalogueItemCard {
    align-self: stretch;
    position: relative;
    font-size: var(--px-medium-size);
    color: var(--color-teal);
  }
  .rm-332900-parentSingleCatalogueItemCard {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
  .frame-parent2SingleCatalogueItemCard {
    border-radius: var(--br-8xs);
    border: 1px solid var(--border-ededed);
    box-sizing: border-box;
    width: 124px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xs);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .gb2SingleCatalogueItemCard {
    position: relative;
    font-size: var(--text-sm-medium-size);
    font-weight: 500;
    font-family: var(--text-sm-medium);
    color: var(--base-black);
    text-align: center;
  }
  .out-of-stockSingleCatalogueItemCard {
    align-self: stretch;
    position: relative;
    font-size: var(--px-medium-size);
    font-family: var(--text-sm-medium);
    color: var(--primary-d71513);
    text-align: left;
  }
  .frame-parent3SingleCatalogueItemCard {
    cursor: pointer;
    border: 1px solid var(--border-ededed);
    padding: var(--padding-5xs);
    background-color: transparent;
    border-radius: var(--br-8xs);
    box-sizing: border-box;
    width: 124px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .frame-parent1SingleCatalogueItemCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    text-align: center;
    font-size: var(--text-sm-medium-size);
  }
  .frame-containerSingleCatalogueItemCard {
    width: 592px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .sku-hrw-not-0001601SingleCatalogueItemCard {
    position: relative;
    color: var(--base-black);
  }
  .availability-in-stock-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--color-teal);
  }
  .shopping-cart-iconSingleCatalogueItemCard {
    position: relative;
    width: 28px;
    height: 28px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .steavenSingleCatalogueItemCard {
    position: relative;
    font-weight: 600;
  }
  .shopping-cart-parentSingleCatalogueItemCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-wrapper2SingleCatalogueItemCard {
    border-radius: var(--br-9xs);
    background-color: var(--primary-d71513);
    width: 279px;
    height: 54px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-11xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .heart-icon1SingleCatalogueItemCard {
    position: relative;
    width: 27px;
    height: 27px;
  }
  .frame-wrapper3SingleCatalogueItemCard {
    border-radius: var(--br-9xs);
    background-color: var(--base-white);
    border: 1px solid var(--base-black);
    box-sizing: border-box;
    width: 226px;
    height: 54px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-11xl);
    align-items: center;
    justify-content: center;
    color: var(--base-black);
  }
  .frame-parent4SingleCatalogueItemCard {
    width: 592px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }
  .frame-wrapper1SingleCatalogueItemCard {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-8xs) 0px 0px;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--base-white);
  }
  .epson-ecotank-l3256-aio-wifi-i-parentSingleCatalogueItemCard {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .singleitemSingleCatalogueItemCard {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--base-black);
    font-family: var(--text-sm-medium);
  }
  
  @media screen and (max-width: 960px) {
  
  
    .epson-ecotank-l3256-aio-wifi-i-parentSingleCatalogueItemCard {
      flex: unset;
      align-self: stretch;
    }
  
  
  }

@media screen and (max-width: 1300px) {
  .singleitemSingleCatalogueItemCard {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .canon-e3370-rd-printer-photo2-parentSingleCatalogueItemCard {
    display: none;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .frame-wrapper2SingleCatalogueItemCard  {
    width: 166px;
    
  }
}


  @media screen and (max-width: 420px) {
    
    .epson-ecotank-l3256SingleCatalogueItemCard {
      font-size: 14px;
    }
  }
  

.ChangePassword__Main__Container{
  width:100%;
}
.ChangePassword__Main__Heading{
  font-family: "Poppins";

}
.InputContainer__Confirm__Password{
  width:100%;
  position: relative;
}
.eye-icon-One{
  color:#101828;
  position:absolute;
  width: 5%;
  top: 16px;
    right: 6px;
}
.eye-icon-Two {
  color: #101828;
  position: absolute;
  width: 5%;
top: 16px;
  right: 6px;
}
.eye-icon-Three{
  color: #101828;
    position: absolute;
    width: 5%;
top: 16px;
  right: 6px;
}
.change__password__parent__block{
    position: relative;
    background-color: var(--base-white);
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: var(--display-sm-semibold-size);
    color: var(--color-gray-100);
    font-family: var(--text-md-semibold);
    font-family: 'Poppins';
    padding: 2.5rem 4rem;
    .header3{
      gap: 25px;
      .change__password__header{
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      h3{
        color: var(--grey-900, #101828);
        text-align: center;
        /* Display sm/SemiBold */
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 126.667% */
        margin-bottom: 0;
      }
      p{
        color: var(--Grey---767676, #888686);
        text-align: center;
        /* 16px/Regular */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
    }
    .hint-text4-message{
      color: var(--grey-600, #475467);
      /* Text sm/Regular */
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      margin-bottom: 0;
    }
}
.avatar-icon1EditPassword {
    position: relative;
    border-radius: var(--br-181xl);
    width: 90px;
    height: 90px;
  }
  .label3EditPassword {
    position: relative;
    font-size: var(--m3-label-large-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: left;
  }
  .avatar-parentEditPassword {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .dropdown1EditPassword {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .label4EditPassword {
    position: relative;
    font-size: var(--m3-label-large-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--grey-700);
    text-align: left;
  }

  .codeEditPassword {
    border: 1px solid var(--grey-300);
    font-family: var(--text-md-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--m3-sys-light-on-primary);
    width: 35px;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding-block: 10px;
    padding-left: 10px;
    padding-right: 5px;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }


  .phoneDivEditPassword {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .input3EditPassword {
    border: 1px solid var(--grey-300);
    font-family: var(--text-md-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--m3-sys-light-on-primary);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    outline: none;
    flex: 1;
    position: relative;
    width:100%;
  }

  .input3EditPassword.phoneEditPassword {
    padding-block: 10px;
    padding-left: none;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .input3EditPassword.phoneEditPassword:hover {
    box-shadow: none;
    border-left: none;
  }

  .input3EditPassword:hover {
    border: 1px solid var(--primary-300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dce8ff;
  }

  .hint-text3EditPassword {
    align-self: stretch;
    position: relative;
    font-size: var(--m3-label-large-size);
    line-height: 20px;
    font-family: var(--text-md-semibold);
    color: var(--grey-600);
    text-align: left;
    display: block;
  }
  .input-fieldEditPassword {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .formEditPassword {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .check-iconEditPassword {
    position: relative;
    width: 22px;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .text29EditPassword {
    position: relative;
    font-size: var(--text-md-semibold-size);
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--primary-d71513);
    text-align: left;
  }
  .input7EditPassword {
    flex: 1;
    height: fit-content;
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--primary-d71513);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .text30EditPassword {
    position: relative;
    font-size: var(--text-md-semibold-size);
    font-weight: 500;
    font-family: var(--text-md-semibold);
    color: var(--grey-767676);
    text-align: left;
  }
  .input8EditPassword {
    cursor: pointer;
    border: 1px solid var(--grey-300);
    padding: var(--padding-3xs) var(--padding-sm);
    background-color: var(--m3-sys-light-on-primary);
    flex: 1;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .input-parentEditPassword {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .dropdown-groupEditPassword {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
  }
  .label-text2EditPassword {
    position: relative;
    font-weight: 600;
    color: white;
    padding-block: 10px;
  }
  .state-layer2EditPassword {
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    align-items: center;
    justify-content: center;
  }
  .button1EditPassword {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-d71513);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    padding: 10px 18px;
    color: var(--base-white, #FFF);
    /* Text md/SemiBold */
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .button1EditPassword:hover {
    box-shadow: var(--shadow-base);
    
  }

  .change__password__block{
    border-radius: var(--br-5xs);
    background-color: var(--m3-sys-light-on-primary);
    // max-width: 360px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    // gap: var(--gap-13xl);
    text-align: center;
    font-size: var(--m3-label-large-size);
    color: var(--m3-sys-light-on-primary);
    font-family: var(--text-md-semibold);
    margin: 0 auto;
  }
  

  .input9EditPassword {
    border: 1px solid var(--grey-300);
    font-family: var(--text-sm-semibold);
    font-size: var(--text-md-semibold-size);
    background-color: var(--base-white);
    align-self: stretch;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    outline: none;
    flex: 2;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  
.prefix-phonePassword {
  width: 50px;
  border: 1px solid var(--grey-300) ;
  border-right: none;
  font-family: var(--text-sm-semibold);
  font-size: var(--text-md-semibold-size);
  background-color: var(--base-white);
  /* align-self: stretch; */
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-right: 0px;
  padding-left: var(--padding-3xs) ;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  border-top-right-radius: 0px;
 border-bottom-right-radius: 0px;
  /* flex: 1; */

}

.phone-divPassword {
  display: flex;
  gap: 0px;
  width: 100%;
}
@media screen and (max-width:"850px"){
  .change__password__parent__block{
    padding:1rem;
  }
    .eye-icon-One {
      color: #101828;
      position: absolute;
      width: 10%;
      top: 16px;
      right: -6px;
    }
  
}
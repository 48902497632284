.bill-detailsEarnPointsBillForm {
    align-self: stretch;
    position: relative;
    font-weight: 500;
  }
  .sku-hrw-not-0001601EarnPointsBillForm {
    position: relative;
  }
  .sku-hrw-not-00016011EarnPointsBillForm {
    position: relative;
    color: var(--base-black);
    text-align: right;
  }
  .sku-hrw-not-0001601-parentEarnPointsBillForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .sku-hrw-not-00016013EarnPointsBillForm {
    position: relative;
    color: var(--color-seagreen);
    text-align: right;
  }
  .sku-hrw-not-00016017EarnPointsBillForm {
    position: relative;
    color: var(--primary-d71513);
    text-align: right;
  }
  .line-divEarnPointsBillForm {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--border-ededed);
    box-sizing: border-box;
    height: 1px;
  }
  .sku-hrw-not-000160112EarnPointsBillForm {
    position: relative;
    font-weight: 500;
  }
  .sku-hrw-not-000160113EarnPointsBillForm {
    position: relative;
    font-weight: 500;
    text-align: right;
  }
  .sku-hrw-not-0001601-parent4EarnPointsBillForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    color: var(--base-black);
  }
  .frame-parent10EarnPointsBillForm {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    font-size: var(--m3-label-large-size);
    color: var(--grey-767676);
  }
  .bill-details-parentEarnPointsBillForm {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
    font-size: var(--px-medium-size);
    color: var(--base-black);
  }
  .label-text3EarnPointsBillForm {
    position: relative;
    font-size: var(--m3-label-large-size);
    font-weight: 600;
    font-family: var(--text-md-semibold);
    color: var(--m3-sys-light-on-primary);
    text-align: center;
  }
  .state-layer3EarnPointsBillForm {
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    align-items: center;
    justify-content: center;
  }
  .button1EarnPointsBillForm {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: var(--primary-d71513);
    align-self: stretch;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .button-wrapperEarnPointsBillForm {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: var(--padding-3xs) 0px 0px;
    align-items: center;
    justify-content: flex-end;
  }
  .frame-parent9EarnPointsBillForm {
    background-color: var(--m3-sys-light-on-primary);
    border-left: 1px solid var(--border-ededed);
    box-sizing: border-box;
    width: 439px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--m3-sys-light-on-primary);
    font-family: var(--text-md-semibold);
  }
  